import React, { HTMLAttributes } from "react";
import { ReactComponent as InfoIcon } from "../../../assets/icons/infoIcon.svg";
import { ReactComponent as LocationIcon } from "../../../assets/icons/locationIcon.svg";
import { ITEM_TYPE_MAPPING } from "../../molecules/subscriptionManageBlock";
import BaseChip from "../baseChip";
import BaseLabel from "../baseLabel";

interface IItemDisplayCard {
  carpakName?: string;
  location?: string;
  interval_count?: number;
  map?: string;
  itemName: string;
  itemTypeID: number;
}

type TItemDisplayCardProps = IItemDisplayCard & HTMLAttributes<HTMLDivElement>;

const ItemDisplayCard: React.FC<TItemDisplayCardProps> = (props) => {
  const {
    carpakName,
    location,
    map,
    itemName,
    itemTypeID,
    interval_count,
    ...otherProps
  } = props;

  const itemInfo = ITEM_TYPE_MAPPING[itemTypeID ?? 1];

  return (
    <div className="" {...otherProps}>
      <BaseLabel text="租用項目" />
      <div className="flex flex-col gap-3 rounded-2xl bg-mainDark p-5">
        {interval_count && (
          <BaseChip
            leftIcon={<InfoIcon />}
            text={interval_count > 1 ? `${interval_count}個月季票計劃` : "月票"}
          />
        )}
        {carpakName && (
          <span className="font-bold text-white text-xl">{carpakName}</span>
        )}
        {location && map && (
          <a
            className="link flex flex-row items-center text-xl gap-2"
            href={map}
            target="_blank"
          >
            <LocationIcon /> {location}
          </a>
        )}
        <div className="flex flex-row gap-2 items-center">
          {itemInfo.icon}
          <div className="text-white text-xs font-bold">{itemName}</div>
        </div>
      </div>
    </div>
  );
};

export default ItemDisplayCard;
