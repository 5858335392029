import React from "react";
import BaseTextInput, { type TBaseTextInputProps } from "../baseTextInput";
interface IBaseCarplateTextInput {
  onChangeCarplate: (carplate: string) => void;
}

type TBaseCarplateTextInputProps = IBaseCarplateTextInput & TBaseTextInputProps;

const BaseCarplateTextInput: React.FC<TBaseCarplateTextInputProps> = (
  props
) => {
  const { onChangeCarplate, ...otherProps } = props;

  const customOnchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[a-zA-Z0-9]{0,8}$/;

    if (regex.test(e.target.value)) {
      const upperCarPlate = e.target.value.toUpperCase();
      const formattedCarPlate = upperCarPlate
        .replaceAll("I", "")
        .replaceAll("O", "")
        .replaceAll("Q", "");

      onChangeCarplate(formattedCarPlate);
    }
  };

  return (
    <BaseTextInput
      placeholder="車牌"
      required
      maxLength={8}
      onChange={customOnchange}
      {...otherProps}
    />
  );
};

export default BaseCarplateTextInput;
