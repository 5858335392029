import React, { HTMLAttributes, useEffect, useState } from "react";
import { loginUserByOtp } from "../../apis/user";
import BaseButton from "../../components/atom/baseButton";
import BaseLabel from "../../components/atom/baseLabel";
import BaseTabs from "../../components/atom/baseTabs";
import BaseTextInput from "../../components/atom/baseTextInput";
import BaseTitle from "../../components/atom/baseTitle";
import OtpRequestButton from "../../components/atom/otpRequestButton";
import SubscriptionManageBlock from "../../components/molecules/subscriptionManageBlock";
import UserInfoBlock from "../../components/molecules/userInfoBlock";
import { _8digitValidator, isLoginExpired } from "../../helper/checker";
import { ROUTE_PATHS } from "../../routes/paths/route-paths";
import useGeneralStore from "../../store/general";

interface IInquirePage {}

type TInquirePageProps = IInquirePage & HTMLAttributes<HTMLDivElement>;

const InquirePage: React.FC<TInquirePageProps> = (props) => {
  const { ...otherProps } = props;
  const [isLoggined, setIsLoggined] = useState<boolean>(false);
  // const [isShowExtraCarplateSection , setIsShowExtraCarplateSection] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [errorMessages, setErrorMessages] = useState<{
    phone?: string;
    otp?: string;
  }>({});

  const tabs = [
    {
      label: "訂閱記錄＆管理",
      key: "subscription",
      content: <SubscriptionManageBlock />,
    },
    {
      label: "我的帳戶",
      key: "account",
      content: (
        <div className="min-h-80 text-3xl text-white font-bold text-center">
          <UserInfoBlock />
        </div>
      ),
    },
  ];

  const setGlobalLoadingState = useGeneralStore((state) => state.setIsLoading);

  const onLogin = async () => {
    try {
      if (otp.length < 6) {
        setErrorMessages((prev) => ({
          ...prev,
          otp: "請輸入6位數字驗證碼",
        }));
        return;
      } else {
        setErrorMessages((prev) => ({ ...prev, otp: "" }));
      }

      setGlobalLoadingState(true);
      const verifyOtpResponse = await loginUserByOtp(phone, otp);

      if (verifyOtpResponse.access_token) {
        setIsLoggined(true);
        // prepare work for login feature
        const loginState = {
          isLoggined: true,
          phone: phone,
          t: verifyOtpResponse.access_token,
          expiresAt: new Date().getTime() + 7 * 24 * 60 * 60 * 1000, // 7 days in milliseconds
        };
        localStorage.setItem("wbloginState", JSON.stringify(loginState));
        setGlobalLoadingState(false);
      } else {
        setGlobalLoadingState(false);
      }
    } catch (error) {
      setGlobalLoadingState(false);
    } finally {
      setGlobalLoadingState(false);
    }
  };

  useEffect(() => {
    // prepare work for login feature
    const checkIsLoginExpired = isLoginExpired();

    if (checkIsLoginExpired) {
      localStorage.removeItem("wbloginState");
      setIsLoggined(false);
    } else {
      setIsLoggined(true);
    }
  }, []);

  if (isLoggined) {
    return (
      <div className="flex flex-1 flex-col gap-8" {...otherProps}>
        <BaseTabs tabs={tabs} className="rounded-none py-3" />
      </div>
    );
  } else {
    return (
      <div className="flex flex-1 flex-col gap-8 px-10" {...otherProps}>
        <BaseTitle className="text-center mt-10" text="登入" />

        <div className="flex flex-col gap-8">
          <BaseTextInput
            placeholder="電話號碼"
            required
            value={phone}
            rightElement={<OtpRequestButton phone={phone} />}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const isPhoneValid = _8digitValidator(e.target.value);
              if (!isPhoneValid) {
                return;
              }
              setPhone(e.target.value);
            }}
            errorMessage={errorMessages?.phone}
          />

          <BaseTextInput
            placeholder="驗證碼"
            required
            value={otp}
            maxLength={6}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const otpRegex = /^[0-9]{0,6}$/;

              if (!otpRegex.test(e.target.value)) {
                return;
              }
              setOtp(e.target.value);
            }}
            errorMessage={errorMessages?.otp}
          />
        </div>
        <div className="flex flex-col gap-4">
          <a
            href={ROUTE_PATHS.REGISTER_PAGE}
            className="text-center text-white text-sm underline"
          >
            <BaseLabel text="尚未持有帳號? 點擊註冊" />
          </a>

          <BaseButton
            onClick={onLogin}
            disabled={phone === "" || otp === ""}
            minWidth="w-full"
            disabledStyle="disabled:bg-mainYellow disabled:bg-opacity-60"
          >
            登入
          </BaseButton>
        </div>
      </div>
    );
  }
};

export default InquirePage;
