import React from "react";
import { _8digitValidator, octopusValidator } from "../../../helper/checker";
import type { IBookingFormValues } from "../../molecules/stepContainer/applyForm/type";
import BaseTextInput, { type TBaseTextInputProps } from "../baseTextInput";

interface IBaseOctopusNumberTextInput {
  octopusNumber: string;
  octopusLastDigit: string;
  onchangeBookingFormValues: (
    key: keyof IBookingFormValues,
    value: string
  ) => void;
}

type TBaseOctopusNumberTextInputProps = IBaseOctopusNumberTextInput &
  TBaseTextInputProps;

const BaseOctopusNumberTextInput: React.FC<TBaseOctopusNumberTextInputProps> = (
  props
) => {
  const {
    octopusNumber,
    octopusLastDigit,
    onchangeBookingFormValues,
    ...otherProps
  } = props;

  const customOctopusNumberOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isOctopusNumberValid = octopusValidator(e.target.value);
    if (!isOctopusNumberValid) {
      return;
    }
    onchangeBookingFormValues("octopusNumber", e.target.value);
  };

  const customOctopusNumberLastDigitOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isLastDigitValid = _8digitValidator(e.target.value);
    if (!isLastDigitValid) {
      return;
    }
    onchangeBookingFormValues("octopusLastDigit", e.target.value);
  };

  return (
    <BaseTextInput
      placeholder="八達通編號(可選填)"
      value={octopusNumber}
      minLength={15}
      rightElement={
        <div className="flex flex-row items-center text-secondaryDisable font-semibold">
          <div>{`(`}</div>
          <input
            maxLength={1}
            type={"text"}
            className={
              "bg-selectedDark text-white focus:outline-none w-5 text-center"
            }
            value={octopusLastDigit}
            onChange={customOctopusNumberLastDigitOnChange}
          />
          <div>{`)`}</div>
        </div>
      }
      onChange={customOctopusNumberOnChange}
      {...otherProps}
    />
  );
};

export default BaseOctopusNumberTextInput;
