import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../routes/paths/route-paths";

const NotFoundPage: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(ROUTE_PATHS.LANDING_PAGE);
  }, []);

  return (
    <div className="flex flex-col justify-center text-white">
      <h1>404,頁面不存在</h1>
    </div>
  );
};

export default NotFoundPage;
