import axios, { AxiosInstance, type AxiosResponse } from "axios";
import { customShowToast } from "../helper/toast";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

const api: AxiosInstance = axios.create({
  baseURL: API_BASE_URL, // Replace with your API base URL
  timeout: 30000, // Set a timeout value if needed
  headers:
    {
      "Content-Type": "application/json", // Set the desired content type
      cms_api_key: API_KEY,
      cms_api_key_type: "booking-web",
    } || {},
});

// Add a request interceptor
// api.interceptors.request.use(
//   //@ts-ignore
//   (config: AxiosRequestConfig) => {
//     // Do something before sending the request
//     console.log("Request is being sent");
//     return config;
//   },
//   (error) => {
//     // Do something with request error
//     return Promise.reject(error);
//   }
// );

// Add a response interceptor
api.interceptors.response.use(
  (response: AxiosResponse) => {
    // Do something with response data
    // console.log("Response was received");
    // console.log(response);
    return response;
  },
  (error) => {
    const errorMessage = error.response.data.message;
    customShowToast(errorMessage);

    // Do something with response error
    return Promise.reject(error);
  }
);

export default api;
