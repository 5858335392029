import { Outlet } from "react-router-dom";
import Footer from "../molecules/footer";
import Header from "../molecules/header";

const ENV = process.env.REACT_APP_ENV;

console.log(`ENV`);
console.log(ENV);

export default function Layout() {
  return (
    <div className="container max-w-[25rem] min-h-[100vh] overflow-auto mx-auto p-4">
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}
