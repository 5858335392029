import React, { HTMLAttributes } from "react";
import Banner from "../../../../assets/images/extraCarplateBanner.png";
import useInfoStateStore from "../../../../store/infoState";
import BaseButton from "../../../atom/baseButton";
import BaseTitle from "../../../atom/baseTitle";
import BaseBreadCrumb from "../../../atom/breadcrumb";
import ExtraCarplateDescriptionBlock from "../../../atom/extraCarplateDescriptionBlock";

interface IExtraCarplatePage {
  goBack: () => void;
  goStep: (step: number) => void;
}

type TExtraCarplatePageProps = IExtraCarplatePage &
  HTMLAttributes<HTMLDivElement>;

const ExtraCarplatePage: React.FC<TExtraCarplatePageProps> = (props) => {
  const { goBack, goStep, ...otherProps } = props;

  const clearExtraCarplateDetails = useInfoStateStore(
    (state) => state.setExtraCarPlateDetails
  );

  const skipOnpress = () => {
    clearExtraCarplateDetails([]);
    goStep(7);
  };

  const addCarplateOnpress = () => {
    goStep(6);
  };

  return (
    <div className="flex flex-1 flex-col gap-4" {...otherProps}>
      <BaseBreadCrumb onClick={goBack}>{`<上一頁`}</BaseBreadCrumb>
      <BaseTitle text="一位多泊計劃+HK$500" />

      <img src={Banner} alt="extraCarplate" className="w-full rounded-2xl" />

      <div className="flex flex-row flex-1 justify-between items-center gap-5">
        <BaseButton
          onClick={skipOnpress}
          className="flex-1 text-mainYellow border border-mainYellow bg-transparent opacity-50 hover:bg-transparent hover:opacity-100 hover:border-mainYellow"
        >
          略過升級
        </BaseButton>
        <BaseButton
          onClick={addCarplateOnpress}
          className="flex-1"
          disabledStyle="disabled:bg-mainYellow disabled:bg-opacity-60"
        >
          加入車牌
        </BaseButton>
      </div>

      <ExtraCarplateDescriptionBlock />
    </div>
  );
};

export default ExtraCarplatePage;
