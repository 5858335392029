import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import tc from "./locales/tc.json";

// i18n.js

// Import the necessary libraries

// Define the translations
const resources = {
  en: { translation: en },
  tc: { translation: tc },
};

// Initialize i18next
i18n.use(initReactI18next).init({
  resources,
  lng: "tc", // Set the default language
  fallbackLng: "tc", // Fallback language if translation is missing
  interpolation: {
    escapeValue: false, // React already escapes values
  },
});

export default i18n;
