import React, { HTMLAttributes, useState } from "react";
import { registerUser } from "../../apis/user";
import BaseButton from "../../components/atom/baseButton";
import BaseLabel from "../../components/atom/baseLabel";
import BaseTextInput from "../../components/atom/baseTextInput";
import BaseTitle from "../../components/atom/baseTitle";
import OtpRequestButton from "../../components/atom/otpRequestButton";
import { _8digitValidator, registerFormValidator } from "../../helper/checker";
import { ROUTE_PATHS } from "../../routes/paths/route-paths";
import useGeneralStore from "../../store/general";

export interface IRegisterForm {
  username: string;
  email: string;
  phone: string;
  password: string;
  confirmPassword: string;
}

interface IRegisterPage {}

type TRegisterPageProps = IRegisterPage & HTMLAttributes<HTMLDivElement>;

const RegisterPage: React.FC<TRegisterPageProps> = (props) => {
  const { ...otherProps } = props;
  const [registerForm, setRegisterForm] = useState<IRegisterForm>({
    username: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });
  const [otp, setOtp] = useState<string>("");

  const [errorMessages, setErrorMessages] = useState<{
    username?: string;
    email?: string;
    phone?: string;
    password?: string;
    otp?: string;
  }>({});

  const setGlobalLoadingState = useGeneralStore((state) => state.setIsLoading);

  const onChangeRegisterForm = (key: keyof IRegisterForm, value: string) => {
    setRegisterForm((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const onRegister = async () => {
    try {
      const errors = registerFormValidator(registerForm);

      setErrorMessages((prev) => ({ ...prev, ...errors }));

      if (otp.length < 6) {
        setErrorMessages((prev) => ({
          ...prev,
          otp: "請輸入6位數字驗證碼",
        }));
        return;
      } else {
        setErrorMessages((prev) => ({ ...prev, otp: "" }));
      }

      setGlobalLoadingState(true);
      const registerResult = await registerUser({
        code: otp,
        phone: registerForm.phone,
        username: registerForm.username,
        // password: registerForm.password,
        // confirm_password: registerForm.confirmPassword,
        email: registerForm.email,
      });
      if (registerResult) {
        const loginState = {
          isLoggined: true,
          phone: registerResult.phone,
          t: registerResult.access_token,
          expiresAt: new Date().getTime() + 7 * 24 * 60 * 60 * 1000, // 7 days in milliseconds
        };
        localStorage.setItem("wbloginState", JSON.stringify(loginState));
        setGlobalLoadingState(false);

        // go to inquire page with login
        window.location.href = ROUTE_PATHS.INQUIRE_PAGE;
      }
    } catch (error) {
      setGlobalLoadingState(false);
    } finally {
      setGlobalLoadingState(false);
    }
  };

  return (
    <div className="flex flex-1 flex-col gap-8 px-10" {...otherProps}>
      <BaseTitle className="text-center mt-10" text="註冊帳號" />

      <div className="flex flex-col gap-4">
        <BaseTextInput
          placeholder="車主名稱"
          required
          value={registerForm.username}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChangeRegisterForm("username", e.target.value);
          }}
          errorMessage={errorMessages?.username}
        />

        <BaseTextInput
          placeholder="電郵"
          required
          value={registerForm.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChangeRegisterForm("email", e.target.value);
          }}
          errorMessage={errorMessages?.email}
        />

        <BaseTextInput
          placeholder="電話號碼"
          required
          value={registerForm.phone}
          rightElement={<OtpRequestButton phone={registerForm.phone} />}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const isPhoneValid = _8digitValidator(e.target.value);
            if (!isPhoneValid) {
              return;
            }
            onChangeRegisterForm("phone", e.target.value);
          }}
          errorMessage={errorMessages?.phone}
        />

        {/* <BasePasswordTextInput
          placeholder="密碼"
          required
          value={registerForm.password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChangeRegisterForm("password", e.target.value);
          }}
          errorMessage={errorMessages?.password}
        />

        <BasePasswordTextInput
          placeholder="確認密碼"
          required
          value={registerForm.confirmPassword}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChangeRegisterForm("confirmPassword", e.target.value);
          }}
          errorMessage={errorMessages?.password}
        /> */}

        <BaseTextInput
          placeholder="驗證碼"
          required
          value={otp}
          maxLength={6}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const otpRegex = /^[0-9]{0,6}$/;

            if (!otpRegex.test(e.target.value)) {
              return;
            }
            setOtp(e.target.value);
          }}
          errorMessage={errorMessages?.otp}
        />
      </div>
      <div className="flex flex-col gap-4">
        <a
          href={ROUTE_PATHS.INQUIRE_PAGE}
          className="text-center text-white text-sm underline"
        >
          <BaseLabel text="已有帳號? 前往登入" />
        </a>
        <BaseButton
          onClick={onRegister}
          disabled={
            registerForm.username === "" ||
            registerForm.phone === "" ||
            otp === ""
          }
          minWidth="w-full"
          disabledStyle="disabled:bg-mainYellow disabled:bg-opacity-60"
        >
          註冊
        </BaseButton>
      </div>
    </div>
  );
};

export default RegisterPage;
