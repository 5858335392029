import dayjs from "dayjs";
import { TOMMOROW, intervalMapping } from "../constants";

// get formatted amount unit text
export const getAmountUnitText = (
  interval: string,
  interval_count: number
  //   price: number
) => {
  if (interval_count > 1) {
    return `/${intervalMapping[interval as keyof typeof intervalMapping]}`;
  } else {
    return `/${intervalMapping[interval as keyof typeof intervalMapping]}`;
  }
};

// get monthly order item start date range config
export const getBookingSelectDateRange = (startDate: string) => {
  const now = new Date();
  const _startDate = new Date(startDate);
  const isDefaultStartDateIsBeforeToday = _startDate >= now;
  const finalStartDate = isDefaultStartDateIsBeforeToday
    ? dayjs(startDate, "YYYY-MM-DD").toDate()
    : TOMMOROW;

  return finalStartDate;
};

// get formatted octopus number and last digit
export const getExtractParts = (
  text: string
): { numberPart: string; insideParentheses: string } => {
  const numberPartMatch = text.match(/^(\d+)\(/);
  const insideParenthesesMatch = text.match(/\((\d+)\)$/);

  const numberPart = numberPartMatch ? numberPartMatch[1] : "";
  const insideParentheses = insideParenthesesMatch
    ? insideParenthesesMatch[1]
    : "";

  return { numberPart, insideParentheses };
};

// get parking location tag
export const getLocationTag = (district_id: number) => {
  if (district_id >= 1 && district_id <= 4) {
    return { key: "hk", value: "TXT_District_Option_HongKong" };
  }
  if (district_id >= 5 && district_id <= 9) {
    return { key: "kl", value: "TXT_District_Option_Kowloon" };
  }
  if (district_id >= 10 && district_id <= 18) {
    return { key: "nt", value: "TXT_District_Option_NewTerritories" };
  }

  return { key: "-", value: "-" };
};
