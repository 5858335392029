import React, { HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

interface IBaseChip {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  text: string;
  chipBaseStyle?: string;
  textStyle?: string;
}

type TBaseChipProps = IBaseChip & HTMLAttributes<HTMLDivElement>;

const BaseChip: React.FC<TBaseChipProps> = (props) => {
  const { leftIcon, rightIcon, text, chipBaseStyle, textStyle, ...otherProps } =
    props;

  return (
    <div
      className={twMerge(
        "flex flex-row items-center bg-mainYellow rounded-xl px-4 py-[2px] gap-1 w-fit",
        chipBaseStyle
      )}
      {...otherProps}
    >
      {leftIcon && leftIcon}
      <span
        className={twMerge(
          "text-xs text-black text-center font-bold",
          textStyle
        )}
      >
        {text}
      </span>
      {rightIcon && rightIcon}
    </div>
  );
};

export default BaseChip;
