import React, { HTMLAttributes, useState } from "react";
import useInfoStateStore from "../../../store/infoState";
import AddExtraCarplatePage from "./addExtraCarplatePage";
import ApplyForm from "./applyForm";
import ConfirmForm from "./confirmForm";
import ExtraCarplatePage from "./extraCarplatePage";
import SelectMonthlyOrderItemPage from "./selectMonthlyOrderItemPage";
import SelectParkingPage from "./selectParkingPage";
import SelectRentDatePage from "./selectRentDatePage";

interface IStepContainer {}

type TStepContainerProps = IStepContainer & HTMLAttributes<HTMLDivElement>;

const StepContainer: React.FC<TStepContainerProps> = (props) => {
  const { ...otherProps } = props;
  const [currentStep, setCurrentStep] = useState(1);

  const { ...infoState } = useInfoStateStore((state) => state);

  console.log(`infoState start`);
  console.log(infoState.selectedCarparkID);
  console.log(infoState.selectedMonthlyItem);
  console.log(infoState.selectedStartDate);
  console.log(infoState.applyFormData);
  console.log(infoState.extraCarPlateDetails);
  console.log(`infoState end`);

  const goNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const goBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const goStep = (step: number) => {
    setCurrentStep(step);
  };

  return (
    <div className="px-3" {...otherProps}>
      {/* select carpark page */}
      {currentStep === 1 && (
        <SelectParkingPage
          onCarparkSelected={(index: number) => goStep(index)}
        />
      )}

      {/* select item page */}
      {currentStep === 2 && (
        <SelectMonthlyOrderItemPage
          goBack={goBack}
          goStep={(index: number) => goStep(index)}
        />
      )}

      {/* selecte start date page */}
      {currentStep === 3 && (
        <SelectRentDatePage goBack={goBack} goNext={goNext} />
      )}

      {/* apply form  */}
      {currentStep === 4 && (
        <ApplyForm
          goBack={goBack}
          goNext={goNext}
          goStep={goStep}
          needFetchUserInfo
        />
      )}

      {/* extra carplate page */}
      {currentStep === 5 && (
        <ExtraCarplatePage goBack={goBack} goStep={goStep} />
      )}

      {/* add extra carplate form */}
      {currentStep === 6 && (
        <AddExtraCarplatePage goBack={goBack} goNext={goNext} />
      )}

      {/* confirm form */}
      {currentStep === 7 && <ConfirmForm goStep={goStep} />}
    </div>
  );
};

export default StepContainer;
