import api from "..";
import type {
  IGetUserDataResponse,
  IRegisterRequest,
  IRegisterResponse,
} from "./type";

export const registerUser = async (registerRequest: IRegisterRequest) => {
  const response = await api.post<IRegisterResponse>(`/auth/user/register`, {
    ...registerRequest,
  });

  return response.data;
};

export const loginUserByOtp = async (phone: string, otp: string) => {
  const response = await api.post<{ access_token: string }>(
    `/auth/user/userOtpLogin`,
    {
      phone,
      otp,
    }
  );

  return response.data;
};

export const getUserData = async (phone: string) => {
  const response = await api.get<IGetUserDataResponse>(
    `/user/findUserByPhone/${phone}`
  );

  return response.data;
};

export const updateUserData = async (updateData: any, token: string) => {
  const response = await api.post<IGetUserDataResponse>(
    `/user/userUpdateInfo`,
    {
      username: updateData.name,
      email: updateData.email,
    },
    {
      headers: {
        authorization: "Bearer " + token,
      },
    }
  );

  return response.data;
};

export const verifyOtpThenCreateUser = async (
  phone: string,
  otp: string,
  username: string,
  email: string
) => {
  const response = await api.post<IGetUserDataResponse>(
    `/user/userCreateSubscriptionByOTP`,
    {
      phone,
      code: otp,
      username,
      email,
    }
  );

  return response.data;
};
