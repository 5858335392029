import { FC } from "react";
import { ReactComponent as WaspCar } from "../../../assets/images/wasp_car.svg";

const MovingCar: FC = () => {
  return (
    <div className="overflow-hidden w-[400px] h-[3.125rem] text-[2.5rem] pt-4">
      <div className="inline-block relative left-[-150px] animate-carMove">
        <WaspCar /> ._-_-_
      </div>
    </div>
  );
};

export default MovingCar;
