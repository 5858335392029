import { Bounce, toast, type TypeOptions } from "react-toastify";

export const customShowToast = (
  message: string,
  type: TypeOptions = "error",
  onCloseAction?: () => void
) => {
  toast(message, {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
    type,
    onClose: onCloseAction,
  });
};
