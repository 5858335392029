import React, { HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";
interface IBaseLabel {
  text: string;
}

type TBaseLabelProps = IBaseLabel & HTMLAttributes<HTMLDivElement>;

const BaseLabel: React.FC<TBaseLabelProps> = (props) => {
  const { text, className, ...otherProps } = props;

  return (
    <div
      className={twMerge("text-xs text-secondaryDisable mb-1", className)}
      {...otherProps}
    >
      {text}
    </div>
  );
};

export default BaseLabel;
