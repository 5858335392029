import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import type { IGetParkingListResponse } from "../apis/parking/type";

type TCarparkStore = {
  carparkInfoList: Array<IGetParkingListResponse>;
  setCarParkInfoList: (carparkInfoList: Array<IGetParkingListResponse>) => void;
};

const initState = {
  carparkInfoList: [],
};

const useCarparkStore = create<TCarparkStore>()(
  immer((set) => ({
    ...initState,
    setCarParkInfoList: (carparkInfoList: Array<IGetParkingListResponse>) => {
      const filteredList = carparkInfoList.filter(
        (item) => item.visible && !item.deleted
      );
      filteredList.sort(
        (a, b) =>
          new Date(b.createdAt).setHours(0, 0, 0, 0) -
          new Date(a.createdAt).setHours(0, 0, 0, 0)
      );

      set((state) => ({ ...state, carparkInfoList: filteredList }));
    },
  }))
);

export default useCarparkStore;
