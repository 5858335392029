import type { IExtraCarplateDetails } from "../components/molecules/stepContainer/addExtraCarplatePage";
import type { IBookingFormValues } from "../components/molecules/stepContainer/applyForm/type";
import type { IRegisterForm } from "../pages/register";

export const checkObjectIsNotEmpty = (obj: Record<string, any>): boolean => {
  return Object.values(obj).some((value) => value.trim().length > 0);
};

export const _8digitValidator = (value: string): boolean => {
  const digitOnlyRegex = /^[0-9]{0,8}$/;
  if (digitOnlyRegex.test(value)) {
    return true;
  }
  return false;
};

export const octopusValidator = (value: string): boolean => {
  const digitOnlyRegex = /^[0-9]{0,15}$/;
  if (digitOnlyRegex.test(value)) {
    return true;
  }
  return false;
};

export const emailFormatValidator = (value: string): boolean => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (emailRegex.test(value)) {
    return true;
  }
  return false;
};

export const hasEmptyCarPlates = (extraPlate: IExtraCarplateDetails[]) => {
  return extraPlate?.some((record) => record.carplate.length === 0);
};

export const hasDuplicateCarPlates = (extraPlate: IExtraCarplateDetails[]) => {
  const carPlateSet = new Set();

  for (const detail of extraPlate) {
    const carPlate = detail.carplate;
    if (carPlateSet.has(carPlate)) {
      return true; // Duplicate found
    }
    carPlateSet.add(carPlate);
  }

  return false; // No duplicates
};

export const hasInvalidOctopusNumber = (
  extraPlate: IExtraCarplateDetails[]
) => {
  return extraPlate?.some(
    (record) =>
      (record.octopusNumber && !record.octopusLastDigit) ||
      (!record.octopusNumber && record.octopusLastDigit)
  );
};

export const applyFormValidator = (bookingDetails: IBookingFormValues) => {
  let errorMessages = {};

  // check if the name is empty
  if (bookingDetails.name.trim().length === 0) {
    errorMessages = { ...errorMessages, name: "請輸入車主名稱" };
  } else {
    errorMessages = { ...errorMessages, name: "" };
  }

  // check if main car plate is empty
  if (bookingDetails.mainCarPlate.trim().length === 0) {
    errorMessages = { ...errorMessages, mainCarPlate: "請輸入車牌號碼" };
  } else {
    errorMessages = { ...errorMessages, mainCarPlate: "" };
  }

  // check if email is empty or invalid
  if (bookingDetails.email.trim().length === 0) {
    errorMessages = { ...errorMessages, email: "請輸入電郵" };
  } else if (!emailFormatValidator(bookingDetails.email)) {
    errorMessages = { ...errorMessages, email: "不正確的電郵格式" };
  } else {
    errorMessages = { ...errorMessages, email: "" };
  }

  // check if phone is empty
  if (bookingDetails.phone.trim().length === 0) {
    errorMessages = { ...errorMessages, phone: "請輸入電話號碼" };
  } else {
    errorMessages = { ...errorMessages, phone: "" };
  }

  // check if octopus number and octopus last digit are both empty or both filled

  if (
    (bookingDetails.octopusNumber && !bookingDetails.octopusLastDigit) ||
    (!bookingDetails.octopusNumber && bookingDetails.octopusLastDigit)
  ) {
    errorMessages = {
      ...errorMessages,
      octopusNumber: "八達通編號格式不正確",
    };
  } else {
    errorMessages = { ...errorMessages, octopusNumber: "" };
  }

  return errorMessages;
};

export const registerFormValidator = (registerDetails: IRegisterForm) => {
  let errorMessages = {};

  // check if the name is empty
  if (registerDetails.username.trim().length === 0) {
    errorMessages = { ...errorMessages, username: "請輸入車主名稱" };
  } else {
    errorMessages = { ...errorMessages, username: "" };
  }

  // check if email is empty or invalid

  if (registerDetails.email.trim().length === 0) {
    errorMessages = { ...errorMessages, email: "請輸入電郵" };
  } else if (!emailFormatValidator(registerDetails.email)) {
    errorMessages = { ...errorMessages, email: "不正確的電郵格式" };
  } else {
    errorMessages = { ...errorMessages, email: "" };
  }

  // check if phone is empty
  if (registerDetails.phone.trim().length === 0) {
    errorMessages = { ...errorMessages, phone: "請輸入電話號碼" };
  } else {
    errorMessages = { ...errorMessages, phone: "" };
  }

  return errorMessages;
};

export const isLoginExpired = () => {
  const loginState = JSON.parse(localStorage.getItem("wbloginState") || "{}");

  if (loginState.isLoggined) {
    return new Date().getTime() > loginState.expiresAt;
  } else {
    return true;
  }
};
