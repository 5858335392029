import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import type { IGetParkingItemsResponse } from "../apis/parking/type";

type TMonthlyOrderItemsStore = {
  monthlyOrderItemList: Array<IGetParkingItemsResponse>;
  setMonthlyOrderItemList: (
    carparkInfoList: Array<IGetParkingItemsResponse>
  ) => void;
};

const initState = {
  monthlyOrderItemList: [],
};

const useMonthlyOrderItemsStore = create<TMonthlyOrderItemsStore>()(
  immer((set) => ({
    ...initState,
    setMonthlyOrderItemList: (
      monthlyOrderItemList: Array<IGetParkingItemsResponse>
    ) => set((state) => ({ ...state, monthlyOrderItemList })),
  }))
);

export default useMonthlyOrderItemsStore;
