import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getMonthlyOrderById } from "../../apis/order";
import { IGetMonthlyOrderResponse } from "../../apis/order/type";
import BookingDetailBlock from "../../components/molecules/bookingDetailBlock";
import GoHomeButton from "../../components/molecules/goHomeButton";
import { ROUTE_PATHS } from "../../routes/paths/route-paths";
import useInfoStateStore from "../../store/infoState";

const PaymentResult: React.FC = () => {
  const { state } = useLocation();
  const orderId = state?.orderId;
  const navigate = useNavigate();
  const [order, setOrder] = useState<IGetMonthlyOrderResponse>();

  const clearInfoState = useInfoStateStore((state) => state.clearInfoState);

  const fetchOrderDetails = async (orderId: string) => {
    try {
      const orderResult = await getMonthlyOrderById(+orderId);

      if (orderResult.id) {
        setOrder(orderResult);
      }
    } catch (error) {}
  };

  useEffect(() => {
    clearInfoState();
    if (orderId) {
      fetchOrderDetails(orderId);
    } else {
      navigate(ROUTE_PATHS.LANDING_PAGE);
    }
  }, [orderId]);

  const formattedExtraCarPlateDetails = order?.extraCarPlates.map((item) => ({
    carplate: item.sub_car_plate,
    octopusNumber: item.octopus_number,
  }));

  return (
    <div className="flex flex-1 flex-col gap-4">
      <span className="text-2xl font-bold text-white mt-10">
        <span className="text-mainYellow">已成功訂閱！</span>請查看您的電郵。
      </span>
      {order && (
        <BookingDetailBlock
          name={order?.u_name}
          carPlate={order?.u_lpn}
          phone={order?.u_tel}
          email={order?.u_email}
          parkingName={order.parking.display_name}
          parkingAddress={order.parking.location}
          startDate={dayjs(order.start_date).format("YYYY-MM-DD")}
          octopusNumber={order?.octopus_number}
          itemTypeID={order.monthlyOrderItem.item_type_id}
          itemName={order.monthlyOrderItem?.display_name}
          interval_count={order.monthlyOrderItem?.interval_count}
          price={order.monthlyOrderItem?.price}
          extra_car_plate_price={order.monthlyOrderItem?.extra_car_plate_price}
          extraCarPlateDetails={formattedExtraCarPlateDetails}
        />
      )}

      <GoHomeButton />
    </div>
  );
};

export default PaymentResult;
