import { type ButtonHTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

interface IBaseButton {
  btnColor?: string;
  textColor?: string;
  minWidth?: string;
  isSquare?: boolean;
  disabledStyle?: string;
}

type TBaseButtonProps = IBaseButton & ButtonHTMLAttributes<HTMLButtonElement>;

const BaseButton: React.FC<TBaseButtonProps> = (props) => {
  const {
    btnColor,
    textColor,
    minWidth,
    isSquare,
    disabledStyle,
    children,
    disabled,
    className,
    ...otherProps
  } = props;

  return (
    <button
      // Specify the type prop as "button"
      className={twMerge(
        "btn flex h-10 min-h-min text-sm font-bold gap-2 hover:bg-lightYellow",
        minWidth ?? "min-w-32",
        btnColor ?? "bg-mainYellow",
        textColor ?? "text-black",
        isSquare ? "rounded-xs" : "rounded-3xl",
        disabled
          ? twMerge(
              "disabled:bg-mainDisable disabled:text-black",
              disabledStyle
            )
          : "",
        className
      )}
      disabled={disabled}
      {...otherProps}
    >
      {children}
    </button>
  );
};

export default BaseButton;
