import React, { HTMLAttributes, useEffect, useState } from "react";
import type { IGetMonthlyOrderResponse } from "../../../../apis/order/type";
import {
  hasDuplicateCarPlates,
  hasEmptyCarPlates,
  hasInvalidOctopusNumber,
} from "../../../../helper/checker";
import { customShowToast } from "../../../../helper/toast";
import useInfoStateStore from "../../../../store/infoState";
import BaseButton from "../../../atom/baseButton";
import BaseCarplateTextInput from "../../../atom/baseCarplateTextInput ";
import BaseOctopusNumberTextInput from "../../../atom/baseOctopusTextInput";
import BaseTitle from "../../../atom/baseTitle";
import BaseBreadCrumb from "../../../atom/breadcrumb";
import ExtraCarplateDescriptionBlock from "../../../atom/extraCarplateDescriptionBlock";

export interface IExtraCarplateDetails {
  carplate: string;
  octopusNumber?: string;
  octopusLastDigit?: string;
}
interface IAddExtraCarplatePage {
  goBack?: () => void;
  goNext: () => void;
  applyingItem?: IGetMonthlyOrderResponse;
  customAction?: (values: IExtraCarplateDetails[]) => void;
}

type TAddExtraCarplatePageProps = IAddExtraCarplatePage &
  HTMLAttributes<HTMLDivElement>;

const AddExtraCarplatePage: React.FC<TAddExtraCarplatePageProps> = (props) => {
  const { goBack, goNext, applyingItem, customAction, ...otherProps } = props;
  const [extraCarplatesLocal, setExtraCarplatesLocal] = useState<
    IExtraCarplateDetails[]
  >([
    {
      carplate: "",
      octopusNumber: "",
      octopusLastDigit: "",
    },
  ]);

  // info store setter
  const {
    selectedMonthlyItem,
    extraCarPlateDetails: extraCarPlateFromStore,
    setExtraCarPlateDetails,
  } = useInfoStateStore((state) => state);

  const mainCarplate = useInfoStateStore(
    (state) => state.applyFormData?.mainCarPlate
  );

  const allExtraCarPlateValid = () => {
    const hasEmpty = hasEmptyCarPlates(extraCarplatesLocal);
    if (hasEmpty) {
      customShowToast("額外車牌不能為空");
      return false;
    }
    const checkingMainCaplate = applyingItem
      ? applyingItem.u_lpn
      : mainCarplate;
    const duplicatedWithMainCarplate = extraCarplatesLocal.some(
      (record) => record.carplate === checkingMainCaplate
    );
    if (duplicatedWithMainCarplate) {
      customShowToast("額外車牌不能和主車牌相同");
      return false;
    }

    const hasDuplicate = hasDuplicateCarPlates(extraCarplatesLocal);
    if (hasDuplicate) {
      customShowToast("額外車牌不能重複");
      return false;
    }

    const invalidOctopusNumber = hasInvalidOctopusNumber(extraCarplatesLocal);

    if (invalidOctopusNumber) {
      customShowToast("八達通卡號和尾數必須同時填寫");
      return false;
    }

    return true;
  };

  const addMoreSection = () => {
    setExtraCarplatesLocal([
      ...extraCarplatesLocal,
      {
        carplate: "",
        octopusNumber: "",
        octopusLastDigit: "",
      },
    ]);
  };

  const goBookingDetail = () => {
    // validate extraCarplates first
    if (!allExtraCarPlateValid()) {
      return;
    }
    if (customAction) {
      customAction(extraCarplatesLocal);
      setExtraCarPlateDetails([]);
    } else {
      setExtraCarPlateDetails(extraCarplatesLocal);
      goNext();
    }
  };

  const onAddExtraCarplate = (index: number, key: string, value: string) => {
    const newExtraCarplates = extraCarplatesLocal.map((carplate, i) => {
      if (i === index) {
        return {
          ...carplate,
          [key]: value,
        };
      }
      return carplate;
    });

    setExtraCarplatesLocal(newExtraCarplates);
  };

  const removeCarplate = (index: number) => {
    setExtraCarplatesLocal(extraCarplatesLocal.filter((_, i) => i !== index));
  };

  // set extraCarPlateDetails from store
  useEffect(() => {
    // auto fill extra carplate from store if exist
    if (extraCarPlateFromStore.length > 0) {
      setExtraCarplatesLocal(extraCarPlateFromStore);
    }
  }, [extraCarPlateFromStore]);

  return (
    <div className="flex flex-1 flex-col gap-4" {...otherProps}>
      {goBack && <BaseBreadCrumb onClick={goBack}>{`<上一頁`}</BaseBreadCrumb>}
      <BaseTitle text="登記車牌資料" />

      <div className="flex flex-row justify-between items-center">
        <div className="font-medium text-sm text-white">登記車牌數量</div>
        <BaseButton
          className="min-w-24 bg-mainDark text-white hover:bg-mainDark hover:border-mainDark hover:bg-opacity-60 border-mainDark"
          onClick={addMoreSection}
        >
          新增
        </BaseButton>
      </div>

      {extraCarplatesLocal.map((record, index) => {
        return (
          <div
            key={index}
            className="flex flex-col gap-4 border-b border-[#666666] pb-4"
          >
            <BaseCarplateTextInput
              value={record.carplate}
              onChangeCarplate={(vale) =>
                onAddExtraCarplate(index, "carplate", vale)
              }
            />
            <BaseOctopusNumberTextInput
              octopusNumber={record.octopusNumber || ""}
              octopusLastDigit={record.octopusLastDigit || ""}
              onchangeBookingFormValues={(key, value) =>
                onAddExtraCarplate(index, key, value)
              }
            />
            <BaseButton
              onClick={() => removeCarplate(index)}
              // disabled={!tncChecked || !clientSecret}
              minWidth="w-full"
              className="bg-mainDark text-white border-mainDark hover:border-mainDark hover:bg-mainDark hover:bg-opacity-60"
            >
              刪減此車牌
            </BaseButton>
          </div>
        );
      })}

      <BaseButton
        onClick={goBookingDetail}
        disabled={extraCarplatesLocal.length === 0}
        minWidth="w-full"
        disabledStyle="disabled:bg-mainYellow disabled:bg-opacity-60"
      >
        總結 +
        {(
          extraCarplatesLocal.length *
          (applyingItem
            ? applyingItem.monthlyOrderItem.extra_car_plate_price
            : selectedMonthlyItem?.extra_car_plate_price!)
        ).toLocaleString("zh-HK", {
          style: "currency",
          currency: "HKD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}
      </BaseButton>
      <ExtraCarplateDescriptionBlock />
    </div>
  );
};

export default AddExtraCarplatePage;
