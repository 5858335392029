import React, { HTMLAttributes, useEffect, useState } from "react";
import { getUserData, updateUserData } from "../../../apis/user";
import { _8digitValidator } from "../../../helper/checker";
import { customShowToast } from "../../../helper/toast";
import useGeneralStore from "../../../store/general";
import BaseButton from "../../atom/baseButton";
import BaseCarplateTextInput from "../../atom/baseCarplateTextInput ";
import BaseChip from "../../atom/baseChip";
import BaseDarkButton from "../../atom/baseDarkButton";
import BaseTextInput from "../../atom/baseTextInput";
import BaseTitle from "../../atom/baseTitle";

interface IUserInfoState {
  name: string;
  mainCarPlate: string;
  email: string;
  phone: string;
}

interface IUserInfoBlock {}

type TUserInfoBlockProps = IUserInfoBlock & HTMLAttributes<HTMLDivElement>;

const UserInfoBlock: React.FC<TUserInfoBlockProps> = (props) => {
  const { ...otherProps } = props;
  const setGlobalLoadingState = useGeneralStore((state) => state.setIsLoading);

  const [userInfo, setUserInfo] = useState<IUserInfoState>({
    name: "",
    mainCarPlate: "",
    email: "",
    phone: "",
  });

  const fetchUserInfo = async () => {
    try {
      const loginState = JSON.parse(
        localStorage.getItem("wbloginState") || "{}"
      );

      setGlobalLoadingState(true);
      const userData = await getUserData(loginState.phone);
      setUserInfo({
        name: userData.username,
        mainCarPlate: userData.user_lpn ?? "",
        email: userData.email,
        phone: userData.phone,
      });
    } catch (error) {
      setGlobalLoadingState(false);
    } finally {
      setGlobalLoadingState(false);
    }
  };

  const onchangeUserInfo = (key: string, value: string) => {
    setUserInfo((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const onUpdateUserInfo = async () => {
    try {
      setGlobalLoadingState(true);
      const loginState = JSON.parse(
        localStorage.getItem("wbloginState") || "{}"
      );

      const updateResult = await updateUserData(userInfo, loginState.t);
      if (updateResult) {
        customShowToast(`新增成功`, "success");
      }
    } catch (error) {
      setGlobalLoadingState(false);
    } finally {
      setGlobalLoadingState(false);
    }
  };

  const logout = () => {
    localStorage.removeItem("wbloginState");
    window.location.reload();
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  return (
    <div className="flex flex-col gap-4" {...otherProps}>
      <BaseTitle text="我的帳戶" />
      <div className="flex flex-col gap-5 my-3 justify-center items-center">
        <BaseTextInput
          placeholder="車主名稱"
          required
          value={userInfo?.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onchangeUserInfo("name", e.target.value);
          }}
          errorMessage=""
        />

        <BaseCarplateTextInput
          disabled
          value={userInfo?.mainCarPlate}
          onChangeCarplate={(value) => {
            onchangeUserInfo("mainCarPlate", value);
          }}
          errorMessage=""
        />

        <BaseTextInput
          placeholder="電郵"
          required
          value={userInfo?.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onchangeUserInfo("email", e.target.value);
          }}
          errorMessage=""
        />

        <BaseTextInput
          disabled
          placeholder="電話號碼"
          required
          value={userInfo?.phone}
          rightElement={
            <BaseChip text="已認證" chipBaseStyle="bg-green-500 w-[68px]" />
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const isPhoneValid = _8digitValidator(e.target.value);
            if (!isPhoneValid) {
              return;
            }
            onchangeUserInfo("phone", e.target.value);
          }}
          errorMessage=""
        />

        <div className="flex flex-col gap-4 w-[90%]">
          <BaseButton
            onClick={onUpdateUserInfo}
            minWidth="w-full"
            disabledStyle="disabled:bg-mainYellow disabled:bg-opacity-60"
          >
            更新設定
          </BaseButton>

          <BaseDarkButton onClick={logout}>登出</BaseDarkButton>
        </div>
      </div>
    </div>
  );
};

export default UserInfoBlock;
