import React, { HTMLAttributes } from "react";

interface IExtraCarplateDescriptionBlock {}

type TExtraCarplateDescriptionBlockProps = IExtraCarplateDescriptionBlock &
  HTMLAttributes<HTMLDivElement>;

const ExtraCarplateDescriptionBlock: React.FC<
  TExtraCarplateDescriptionBlockProps
> = (props) => {
  const { ...otherProps } = props;

  return (
    <div
      className="bg-mainDark rounded-2xl p-4 text-white text-xs"
      {...otherProps}
    >
      <span>
        升級至「一位多泊」計劃，
        <span className="text-mainYellow">你可以登記多部車牌進入停車場</span>
        ，你可以與朋友共用車位或者方便擁有超過一部車的你。
      </span>
      <div className="h-4"></div>
      <span className="text-[0.625rem]">
        *「一位多泊」計劃只適用於月租客戶升級；如第2部車同時入場需額外繳付時租費用。
      </span>
    </div>
  );
};

export default ExtraCarplateDescriptionBlock;
