import React, { HTMLAttributes, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { getParkingList } from "../../../../apis/parking";
import type { IGetParkingListResponse } from "../../../../apis/parking/type";
import { ReactComponent as ArrowRightBlackIcon } from "../../../../assets/icons/parkSelectArrowRight.svg";
import { getLocationTag } from "../../../../helper/format";
import useCarparkStore from "../../../../store/carpark";
import useGeneralStore from "../../../../store/general";
import useInfoStateStore from "../../../../store/infoState";
import BaseTabs from "../../../atom/baseTabs";
import BaseTitle from "../../../atom/baseTitle";

interface ISelectParkingPage {
  onCarparkSelected: (index: number) => void;
}

type TSelectParkingPageProps = ISelectParkingPage &
  HTMLAttributes<HTMLDivElement>;

const SelectParkingPage: React.FC<TSelectParkingPageProps> = (props) => {
  const { onCarparkSelected, ...otherProps } = props;
  const { t } = useTranslation();

  const [filteredParkingList, setFilteredParkingList] = React.useState<
    IGetParkingListResponse[]
  >([]);

  const setGlobalLoadingState = useGeneralStore((state) => state.setIsLoading);

  const { clearInfoState: resetInfoStore } = useInfoStateStore(
    (state) => state
  );

  const setCarparkInfoList = useCarparkStore(
    (state) => state.setCarParkInfoList
  );

  const carParkInfo = useCarparkStore((state) => state.carparkInfoList);

  const tabs = [
    {
      label: "TXT_District_Option_All",
      key: "all",
      content: (
        <ParkingListContentTab
          contentList={filteredParkingList}
          onCarparkSelected={onCarparkSelected}
        />
      ),
    },
    {
      label: "TXT_District_Option_Kowloon",
      key: "kl",
      content: (
        <ParkingListContentTab
          contentList={filteredParkingList}
          onCarparkSelected={onCarparkSelected}
        />
      ),
    },
    {
      label: "TXT_District_Option_HongKong",
      key: "hk",
      content: (
        <ParkingListContentTab
          contentList={filteredParkingList}
          onCarparkSelected={onCarparkSelected}
        />
      ),
    },
    {
      label: "TXT_District_Option_NewTerritories",
      key: "nt",
      content: (
        <ParkingListContentTab
          contentList={filteredParkingList}
          onCarparkSelected={onCarparkSelected}
        />
      ),
    },
  ];

  // get parking list api
  const fetchAllParkingList = async () => {
    try {
      setGlobalLoadingState(true);
      const carparkResponse = await getParkingList();
      if (carparkResponse) {
        setCarparkInfoList(carparkResponse);
        setGlobalLoadingState(false);
      }
    } catch (error) {
      setGlobalLoadingState(false);
    } finally {
      setGlobalLoadingState(false);
    }
  };

  useEffect(() => {
    resetInfoStore();

    fetchAllParkingList();
  }, []);

  useEffect(() => {
    updateDistrictList(0);
  }, [carParkInfo]);

  // when district tab clicked
  const updateDistrictList = (index: number) => {
    const chosenKey = tabs[index].key;

    if (chosenKey === "all") {
      setFilteredParkingList(carParkInfo);
    } else {
      const filteredList = carParkInfo.filter((parking) => {
        const matchObject = getLocationTag(parking.district_id);
        return matchObject.key === chosenKey;
      });
      setFilteredParkingList(filteredList);
    }
  };

  return (
    <div className="flex flex-1 flex-col" {...otherProps}>
      {/* title section */}
      <div className="flex flex-1 flex-col my-6 justify-center items-center text-white">
        <span className="font-medium mb-2">{t("TXT_Step1_Title")}</span>
        <BaseTitle text={t("TXT_Step1_SubTitle")} />
      </div>

      {/* tab section */}
      <BaseTabs tabs={tabs} onTabClick={updateDistrictList} />
    </div>
  );
};

const ParkingListContentTab = ({
  contentList,
  onCarparkSelected,
}: {
  contentList: IGetParkingListResponse[];
  onCarparkSelected: (index: number) => void;
}) => {
  const { t } = useTranslation();

  const selectCarparkHandler = (carparkID: number) => {
    // store selected carpark id into store
    useInfoStateStore.getState().setSelectCarpark(carparkID.toString());
    onCarparkSelected(2);
  };

  return (
    <div className="grid grid-cols-2 gap-2">
      {contentList.length === 0 && (
        <div className="col-span-2 text-center text-white font-bold text-xl">
          此地區暫沒有停車場
        </div>
      )}

      {contentList.map((parking) => {
        const tags = getLocationTag(parking.district_id);

        return (
          <div
            className="relative group border border-selectedDark hover:border hover:border-mainYellow rounded-lg cursor-pointer p-1"
            key={parking.id}
            onClick={() => selectCarparkHandler(parking.id)}
          >
            {/* cover */}
            <img
              className="w-full h-32 object-cover rounded-lg aspect-video"
              src={parking.cover}
              alt={parking.display_name}
            />

            {/* tag */}
            <div className="absolute top-1/2 left-4 w-10 h-4 text-[0.5rem] text-center text-white border-white border rounded-sm bg-[#29303A] bg-opacity-30">{`${t(tags.value)}`}</div>

            <div className="flex flex-row flex-1 gap-2 items-center justify-between mt-2">
              <div className="flex flex-col flex-1 gap-1">
                <div className="text-sm font-medium text-white">
                  {parking.display_name}
                </div>
                <div className="min-w-8 text-center text-xs text-black rounded-2xl px-2 py-[0.0313rem] bg-mainYellow w-fit">
                  {parking.name}
                </div>
              </div>
              <div className="flex justify-center items-center rounded-full bg-mainDark w-9 h-9 group-hover:bg-mainYellow group-hover:bg-opacity-30">
                <ArrowRightBlackIcon width={14} height={12} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SelectParkingPage;
