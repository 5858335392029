import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import dayjs from "dayjs";
import React, { HTMLAttributes, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  createSubscriptionAndOrder,
  initStripeIntents,
} from "../../../../apis/subscription";
import { ROUTE_PATHS } from "../../../../routes/paths/route-paths";
import useCarparkStore from "../../../../store/carpark";
import useGeneralStore from "../../../../store/general";
import useInfoStateStore from "../../../../store/infoState";
import BaseButton from "../../../atom/baseButton";
import BaseTitle from "../../../atom/baseTitle";
import BaseBreadCrumb from "../../../atom/breadcrumb";
import BookingDetailBlock from "../../bookingDetailBlock";

interface IConfirmForm {
  goStep: (step: number) => void;
  token?: string;
}

type TConfirmFormProps = IConfirmForm & HTMLAttributes<HTMLDivElement>;

const ConfirmForm: React.FC<TConfirmFormProps> = (props) => {
  const { goStep, token, ...otherProps } = props;
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [tncChecked, setTncChecked] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [customerId, setCustomerId] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [readyToRedirect, setReadyToRedirect] = useState("");

  const {
    selectedCarparkID,
    selectedMonthlyItem,
    selectedStartDate,
    applyFormData,
    extraCarPlateDetails,
    clearInfoState,
  } = useInfoStateStore((state) => state);

  const carparkInfoList = useCarparkStore.getState().carparkInfoList;
  const selectedCarparkInfo = carparkInfoList.find(
    (carpark) => carpark.id.toString() === selectedCarparkID
  );

  const setGlobalLoadingState = useGeneralStore((state) => state.setIsLoading);

  // try init stripe for client secret
  const fetchClientSecret = useCallback(async () => {
    try {
      setGlobalLoadingState(true);
      const res = await initStripeIntents(applyFormData?.userID!);
      if (res) {
        const responseBody = await res;
        console.log(`init intents responseBody success`);
        // console.log(responseBody);
        setClientSecret(responseBody.client_secret);
        setCustomerId(responseBody.customer);
        setGlobalLoadingState(false);
      }
    } catch (error) {
      //@ts-ignore
      setErrorMessage(error.message);
      setGlobalLoadingState(false);
    }
  }, []);

  const autoCreateSubscription = async (
    payment_methodId: string,
    paymentIntentId: string
  ) => {
    try {
      if (applyFormData && selectedStartDate && selectedMonthlyItem) {
        const formattedExtraCarPlateDetails: {
          sub_car_plate: string;
          octopus_number?: string;
        }[] = extraCarPlateDetails.map((item) => {
          return {
            sub_car_plate: item.carplate,
            octopus_number: item.octopusNumber
              ? `${item.octopusNumber}(${item.octopusLastDigit})`
              : undefined,
          };
        });

        const createResult = await createSubscriptionAndOrder({
          priceId: selectedMonthlyItem?.stripePriceId!,
          name: applyFormData?.name,
          email: applyFormData.email,
          phone: applyFormData.phone,
          startDate: dayjs(selectedStartDate).format("YYYY-MM-DD"),
          carPlate: applyFormData?.mainCarPlate,
          monthlyOrderItemId: selectedMonthlyItem?.id,
          paymentMethodId: payment_methodId,
          paymentIntentId: paymentIntentId,
          customerId: customerId,
          type: "wasp",
          octopus_number:
            applyFormData.octopusNumber && applyFormData?.octopusLastDigit
              ? `${applyFormData.octopusNumber}(${applyFormData?.octopusLastDigit})`
              : undefined,
          wl_token: token || undefined,
          extra_car_plates: formattedExtraCarPlateDetails,
          user_id: applyFormData.userID ?? undefined,
        });

        // error case
        if (!createResult) {
          setErrorMessage(`發生未知錯誤`);
          setGlobalLoadingState(false);
        } else {
          // normal case
          if (createResult.id) {
            setGlobalLoadingState(false);
            clearInfoState();
            setReadyToRedirect(createResult.id.toString());
          } else {
            // special case

            if (createResult === "duplicated") {
              setErrorMessage(`此停車場已存在相同車牌的訂閱！`);
            } else if (createResult === "deleted") {
              setErrorMessage(`找不到該訂閱項目`);
            } else if (createResult === "fulled") {
              setErrorMessage(`抱歉，當前車場已滿。`);
            }
            setGlobalLoadingState(false);
          }
        }
      }
    } catch (error) {
      setGlobalLoadingState(false);
    } finally {
      setGlobalLoadingState(false);
    }
  };

  const checkOutHandler = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    try {
      setGlobalLoadingState(true);
      if (clientSecret) {
        const { setupIntent, error } = await stripe.confirmCardSetup(
          clientSecret,
          {
            payment_method: card ? { card } : undefined,
          }
        );

        if (error) {
          setGlobalLoadingState(false);
          //@ts-ignore
          setErrorMessage(error.message);
        } else {
          // Setup Intent was successful
          console.log("setupIntent success");
          if (setupIntent.status === "succeeded") {
            autoCreateSubscription(
              setupIntent?.payment_method as string,
              setupIntent.id
            );
          }
        }
      } else {
        setErrorMessage("unexpected error");
        setGlobalLoadingState(false);
      }
    } catch (error) {
      setGlobalLoadingState(false);
      // @ts-ignore
      setErrorMessage(error.message);
    }
  };

  const customGoBack = () => {
    if (!selectedMonthlyItem?.stripe_extra_car_plate_price_id) {
      goStep(4);
    } else {
      if (extraCarPlateDetails.length > 0) {
        goStep(6);
      } else {
        goStep(5);
      }
    }
  };

  useEffect(() => {
    fetchClientSecret();
  }, []);

  useEffect(() => {
    if (readyToRedirect) {
      // redirect to payment result page
      navigate(ROUTE_PATHS.PAYMENT_RESULT_PAGE, {
        state: {
          orderId: readyToRedirect,
        },
      });
    }
  }, [readyToRedirect]);

  return (
    <div className="flex flex-1 flex-col gap-4" {...otherProps}>
      <BaseBreadCrumb onClick={customGoBack}>{`<上一頁`}</BaseBreadCrumb>
      <BaseTitle text="確認資料及付款" />

      {/* booking detail block */}
      {applyFormData && (
        <BookingDetailBlock
          name={applyFormData?.name}
          carPlate={applyFormData.mainCarPlate}
          phone={applyFormData?.phone}
          email={applyFormData?.email}
          parkingName={selectedCarparkInfo?.display_name!}
          parkingAddress={selectedCarparkInfo?.location!}
          startDate={dayjs(selectedStartDate).format("YYYY-MM-DD")}
          octopusNumber={
            applyFormData?.octopusNumber
              ? `${applyFormData?.octopusNumber}(${applyFormData?.octopusLastDigit})`
              : undefined
          }
          itemTypeID={selectedMonthlyItem?.item_type_id!}
          itemName={
            selectedMonthlyItem?.description
              ? `${selectedMonthlyItem?.display_name}(${selectedMonthlyItem?.description})`
              : selectedMonthlyItem?.display_name!
          }
          interval_count={selectedMonthlyItem?.interval_count || 1}
          price={selectedMonthlyItem?.price || 0}
          extra_car_plate_price={
            selectedMonthlyItem?.extra_car_plate_price || 0
          }
          extraCarPlateDetails={extraCarPlateDetails}
        />
      )}

      {/* tnc section */}
      <div className="text-[0.625rem] text-white font-medium">
        *月租車位計劃將自動續期。計劃可隨時取消。
        <a
          href={"/example.pdf"}
          target="_blank"
          className="link text-mainYellow"
        >
          進一步了解
        </a>
      </div>

      {/* checkbox */}
      <div className="flex flex-row items-center text-[0.625rem] text-white font-medium gap-2">
        <input
          type="checkbox"
          className="checkbox w-4 h-4 checkbox-warning rounded-md"
          checked={tncChecked}
          onChange={() => setTncChecked((prev) => !prev)}
        />
        <span>
          我同意
          <a
            href="https://parkingbees.com.hk/assets/pdf/parkingbees-tnc.pdf"
            target="_blank"
            className="link text-mainYellow"
          >
            條款及細則
          </a>
          &nbsp; 及 &nbsp;
          <a
            href="https://parkingbees.com.hk/assets/pdf/parkingbees-monthly-tnc.pdf"
            target="_blank"
            className="link text-mainYellow"
          >
            月租車位使用條款
          </a>
        </span>
      </div>

      {/* card element */}
      <div>
        {clientSecret && (
          <div className="bg-selectedDark p-3 rounded-2xl text-white border border-white">
            <CardElement
              options={{
                style: {
                  base: {
                    backgroundColor: "transparent",
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "white",
                    "::placeholder": {
                      color: "white",
                    },
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
                hidePostalCode: true,
              }}
            />
          </div>
        )}
        {errorMessage && <div className="text-alertRed">{errorMessage}</div>}
      </div>

      <BaseButton
        onClick={checkOutHandler}
        disabled={!tncChecked || !clientSecret}
        minWidth="w-full"
        disabledStyle="disabled:bg-mainYellow disabled:bg-opacity-60"
      >
        訂閱
      </BaseButton>
    </div>
  );
};

export default ConfirmForm;
