import React, { HTMLAttributes, useEffect } from "react";
import { getParkingItems } from "../../../../apis/parking";

import useCarparkStore from "../../../../store/carpark";
import useGeneralStore from "../../../../store/general";
import useInfoStateStore from "../../../../store/infoState";
import useMonthlyOrderItemsStore from "../../../../store/monthlyOrderItems";
import BaseTitle from "../../../atom/baseTitle";
import BaseBreadCrumb from "../../../atom/breadcrumb";
import GalleryButton from "../../galleryButton";
import MonthlyOrderItemCard from "../../monthlyOrderItemCard";

interface ISelectMonthlyOrderItemPage {
  goStep: (index: number) => void;
  goBack: () => void;
}

type TSelectMonthlyOrderItemPageProps = ISelectMonthlyOrderItemPage &
  HTMLAttributes<HTMLDivElement>;

const SelectMonthlyOrderItemPage: React.FC<TSelectMonthlyOrderItemPageProps> = (
  props
) => {
  const { goStep, goBack, ...otherProps } = props;

  const setGlobalLoadingState = useGeneralStore((state) => state.setIsLoading);
  const isFetching = useGeneralStore((state) => state.isLoading);

  // get related carpark info from store
  const selectedCarparkID = useInfoStateStore(
    (state) => state.selectedCarparkID
  );
  const {
    clearSelectItem,
    clearSelectStartDate,
    clearApplyFormData,
    clearExtraCarPlateDetails,
  } = useInfoStateStore((state) => state);

  const carparkInfoList = useCarparkStore((state) => state.carparkInfoList);
  const selectedCarparkInfo = carparkInfoList.find(
    (carpark) => carpark.id.toString() === selectedCarparkID
  );

  // getter and setter for monthly order items
  const setMonthlyOrderItemList = useMonthlyOrderItemsStore(
    (state) => state.setMonthlyOrderItemList
  );

  const monthlyOrderItemsInfo = useMonthlyOrderItemsStore(
    (state) => state.monthlyOrderItemList
  );

  // Fetch related carpark items
  const fetchRelatedCarparkItems = async () => {
    try {
      setGlobalLoadingState(true);
      const monthlyOrderItems = await getParkingItems(selectedCarparkID);
      if (monthlyOrderItems) {
        setMonthlyOrderItemList(monthlyOrderItems);
      }
    } catch (error) {
      setGlobalLoadingState(false);
    } finally {
      setGlobalLoadingState(false);
    }
  };

  // reset info store when re-select item
  useEffect(() => {
    clearSelectItem();
    clearSelectStartDate();
    clearApplyFormData();
    clearExtraCarPlateDetails();
  }, []);

  useEffect(() => {
    fetchRelatedCarparkItems();
  }, [selectedCarparkID]);

  return (
    <div className="flex flex-1 flex-col gap-4" {...otherProps}>
      <BaseBreadCrumb onClick={goBack}>{`<上一頁`}</BaseBreadCrumb>

      <div className="flex flex-row justify-between items-center">
        <BaseTitle text={selectedCarparkInfo?.display_name || ""} />

        <GalleryButton />
      </div>

      {(isFetching && (
        <div className="flex flex-col gap-4 mb-4">
          {[1, 2, 3].map((item) => {
            return (
              <div className="flex flex-row gap-4" key={item}>
                <div className="flex flex-col justify-center skeleton h-36 w-full p-4">
                  <div className="skeleton h-4 w-32 m-1"></div>
                  <div className="skeleton h-12 w-64 m-1"></div>
                  <div className="skeleton h-4 w-40 m-1"></div>
                </div>
              </div>
            );
          })}
        </div>
      )) || (
        <div className="flex flex-col gap-4 mb-4">
          {monthlyOrderItemsInfo &&
            monthlyOrderItemsInfo.map((item) => {
              return (
                <MonthlyOrderItemCard
                  key={item.id}
                  item={item}
                  goStep={goStep}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

export default SelectMonthlyOrderItemPage;
