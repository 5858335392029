import React, { type ButtonHTMLAttributes } from "react";
import BaseButton from "../baseButton";

interface IBaseDarkButton {}

type TBaseDarkButtonProps = IBaseDarkButton &
  ButtonHTMLAttributes<HTMLButtonElement>;

const BaseDarkButton: React.FC<TBaseDarkButtonProps> = (props) => {
  const { children, ...otherProps } = props;

  return (
    <BaseButton
      className="bg-mainDark border-mainYellow hover:border-mainYellow hover:bg-mainDark hover:bg-opacity-40 text-mainYellow"
      minWidth="w-full"
      {...otherProps}
    >
      {children}
    </BaseButton>
  );
};

export default BaseDarkButton;
