import React, { HTMLAttributes } from "react";

interface IBaseLoading {}

type TBaseLoadingProps = IBaseLoading & HTMLAttributes<HTMLDivElement>;

const BaseLoading: React.FC<TBaseLoadingProps> = (props) => {
  const { ...otherProps } = props;

  return (
    <div className="fixed left-0 top-0 z-[999999] flex h-full w-full flex-col items-center justify-center bg-slate-900/70">
      <React.Fragment>
        <span className="loading loading-dots loading-lg"></span>
      </React.Fragment>
    </div>
  );
};

export default BaseLoading;
