import dayjs from "dayjs";

export const intervalMapping: Record<
  "year" | "month" | "week" | "day",
  string
> = {
  year: "年",
  month: "月",
  week: "周",
  day: "日",
} as const;

export const queueStatusMap: Record<number, string> = {
  1: "pending",
  2: "active",
  3: "completed",
  4: "expired",
} as const;

export const TOMMOROW = dayjs().add(1, "day").toDate();

export const AWS_GLOBAL_IMAGE_URL =
  process.env.REACT_APP_AWS_GLOBAL_IMAGES_BUCKET_URL;
