import React, { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { getOtp } from "../../../apis/otp";
import { customShowToast } from "../../../helper/toast";

interface OtpRequestButtonProps {
  phone: string;
}

const OtpRequestButton: React.FC<OtpRequestButtonProps> = ({ phone }) => {
  const [timer, setTimer] = useState(0);

  const triggerValidationCodeSend = async () => {
    setTimer(60); // Reset the timer to 60 seconds

    try {
      const res = await getOtp(phone);
      if (res) {
        customShowToast(
          res.message === "success" ? "已發送SMS" : "發送SMS失敗",
          res.message === "success" ? "success" : "error"
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  return (
    <button
      className={twMerge(
        "text-xs font-medium px-3 py-1 rounded-3xl bg-mainYellow text-black w-[4.625rem]",
        (timer !== 0 && timer !== 60) || phone === ""
          ? "bg-opacity-60"
          : "hover:bg-secondaryYellow"
      )}
      onClick={triggerValidationCodeSend}
      disabled={(timer !== 0 && timer !== 60) || phone === ""}
    >
      {timer == 0 ? "驗證碼" : `${timer}s`}
    </button>
  );
};

export default OtpRequestButton;
