// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import {
  A11y,
  Autoplay,
  EffectCoverflow,
  Navigation,
  Pagination,
} from "swiper/modules";

// Import Swiper styles

import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { SwiperOptions } from "swiper/types/swiper-options";

export interface IBannerSlideContent {
  image: string;
  link: string;
  customWidth?: number;
}

interface IBannerSwiperProps {
  slideObject: Array<IBannerSlideContent>;
}

const BannerSwiper = (props: IBannerSwiperProps & SwiperOptions) => {
  const { slideObject, ...otherProps } = props;
  const { t } = useTranslation();
  return (
    <>
      <div>{t("TXT_You_May_Interested")}</div>
      <Swiper
        centeredSlides={true}
        slidesPerView={"auto"}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination, Navigation, EffectCoverflow, A11y]}
        effect={"coverflow"}
        pagination={true}
        {...otherProps}
      >
        {slideObject.map((slide) => {
          return (
            <SwiperSlide key={slide.link}>
              <a
                className="flex justify-center"
                href={slide.link}
                target="_blank"
              >
                <img className="max-w-full object-contain" src={slide.image} />
              </a>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default BannerSwiper;
