import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

type TGeneralStore = {
  isLoading: boolean;
  setIsLoading: (state: boolean) => void;
};

const initState = {
  isLoading: false,
};

const useGeneralStore = create<TGeneralStore>()(
  immer((set) => ({
    ...initState,
    setIsLoading: (isLoading: boolean) => set({ isLoading }),
  }))
);

export default useGeneralStore;
