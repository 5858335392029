import React, { HTMLAttributes, useCallback, useState } from "react";
import { ReactComponent as GalleryIcon } from "../../../assets/icons/galleryIcon.svg";
import { AWS_GLOBAL_IMAGE_URL } from "../../../constants";
import useCarparkStore from "../../../store/carpark";
import useInfoStateStore from "../../../store/infoState";
import GalleryPreviewModal from "../galleryPreviewModal";

interface IGalleryButton {}

type TGalleryButtonProps = IGalleryButton & HTMLAttributes<HTMLDivElement>;

const GalleryButton: React.FC<TGalleryButtonProps> = (props) => {
  const { ...otherProps } = props;

  const [showGallery, setShowGallery] = useState(false);

  // get related carpark info from store
  const selectedCarparkID = useInfoStateStore(
    (state) => state.selectedCarparkID
  );
  const carparkInfoList = useCarparkStore((state) => state.carparkInfoList);
  const selectedCarparkInfo = carparkInfoList.find(
    (carpark) => carpark.id.toString() === selectedCarparkID
  );

  const galleryData = selectedCarparkInfo?.gallery_images
    ?.split(",")
    .map((image) => `${AWS_GLOBAL_IMAGE_URL}/${image}`);

  const handleModalOpen = useCallback(() => {
    setShowGallery(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setShowGallery(false);
  }, []);

  if (
    !selectedCarparkInfo?.gallery_images ||
    selectedCarparkInfo?.gallery_images.length === 0
  ) {
    return null;
  }

  return (
    <>
      {galleryData && (
        <button onClick={handleModalOpen}>
          <GalleryIcon />
        </button>
      )}

      {galleryData && galleryData?.length > 0 && (
        <GalleryPreviewModal
          open={showGallery}
          onClose={handleModalClose}
          swiperData={galleryData}
        />
      )}
    </>
  );
};

export default GalleryButton;
