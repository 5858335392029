import React, { DialogHTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

interface IBaseModal {
  title?: string;
  open: boolean;
  onClose: () => void;
  showCloseButton?: boolean;
  customWidth?: string;
  titleStyle?: string;
  modalBaseStyle?: string;
}

export type TBaseModalProps = IBaseModal &
  DialogHTMLAttributes<HTMLDialogElement>;

const BaseModal: React.FC<TBaseModalProps> = ({
  title,
  open,
  onClose,
  showCloseButton = false,
  customWidth,
  modalBaseStyle,
  titleStyle,
  children,
  ...otherProps
}) => {
  return (
    <dialog
      className={`modal ${open ? "modal-open" : "hidden"}`}
      {...otherProps}
    >
      <div
        className={twMerge(
          "modal-box bg-transparent",
          customWidth,
          modalBaseStyle
        )}
      >
        <div className="flex flex-row flex-1 justify-between items-center gap-2">
          <div className={titleStyle}>{title}</div>
          {showCloseButton && (
            <button
              className="btn btn-square btn-outline ml-auto"
              onClick={onClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="white"
                viewBox="0 0 24 24"
                stroke="white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          )}
        </div>
        {children}
      </div>
      <form method="dialog" className="modal-backdrop">
        <button onClick={onClose}></button>
      </form>
    </dialog>
  );
};

export default BaseModal;
