import React, { HTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

interface IBaseTitle {
  text: string;
}

type TBaseTitleProps = IBaseTitle & HTMLAttributes<HTMLDivElement>;

const BaseTitle: React.FC<TBaseTitleProps> = (props) => {
  const { text, className, ...otherProps } = props;

  return (
    <div
      className={twMerge("font-bold text-white text-2xl", className)}
      {...otherProps}
    >
      {text}
    </div>
  );
};

export default BaseTitle;
