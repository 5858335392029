import React, { HTMLAttributes, useEffect, useState } from "react";
import { ReactComponent as LocationIcon } from "../../../../assets/icons/locationIcon.svg";
import useCarparkStore from "../../../../store/carpark";
import useInfoStateStore from "../../../../store/infoState";
import BaseBreadCrumb from "../../../atom/breadcrumb";
import GalleryButton from "../../galleryButton";
import MonthlyOrderItemCard from "../../monthlyOrderItemCard";

import dayjs from "dayjs";
import { Swiper, SwiperSlide } from "swiper/react";

import { FreeMode, Scrollbar } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { TOMMOROW } from "../../../../constants";
import { getBookingSelectDateRange } from "../../../../helper/format";
import BaseButton from "../../../atom/baseButton";
import BaseLabel from "../../../atom/baseLabel";
import BaseTitle from "../../../atom/baseTitle";

interface ISelectRentDatePage {
  goNext: () => void;
  goBack?: () => void;
}

type TSelectRentDatePageProps = ISelectRentDatePage &
  HTMLAttributes<HTMLDivElement>;

const SelectRentDatePage: React.FC<TSelectRentDatePageProps> = (props) => {
  const { goNext, goBack, ...otherProps } = props;
  const [startDate, setStartDate] = useState(TOMMOROW);

  const selectedMonthlyItem = useInfoStateStore(
    (state) => state.selectedMonthlyItem
  );

  const carparkInfoList = useCarparkStore((state) => state.carparkInfoList);

  const selectedCarparkInfo = carparkInfoList.find(
    (carpark) =>
      carpark.id.toString() === selectedMonthlyItem?.parking_id.toString()
  );

  const startDataInStore = useInfoStateStore(
    (state) => state.selectedStartDate
  );

  const setStartDateToStore = useInfoStateStore(
    (state) => state.setSelectStartDate
  );

  const customGoNext = () => {
    setStartDateToStore(startDate);
    goNext();
  };

  // loop date range by item config
  const DAYS = [];

  const getDefaultStartDate = getBookingSelectDateRange(
    selectedMonthlyItem?.start_date!
  );

  for (let i = 0; i <= (selectedMonthlyItem?.maximum_period ?? 7) - 1; i++) {
    DAYS.push(dayjs(getDefaultStartDate).add(i, "days"));
  }

  useEffect(() => {
    if (startDataInStore) {
      setStartDate(startDataInStore);
    }
  }, [startDataInStore]);

  return (
    <div className="flex flex-1 flex-col gap-4" {...otherProps}>
      {goBack && <BaseBreadCrumb onClick={goBack}>{`<上一頁`}</BaseBreadCrumb>}
      {selectedCarparkInfo && (
        <BaseTitle text={selectedCarparkInfo?.display_name || ""} />
      )}
      {selectedCarparkInfo && (
        <div className="flex flex-row justify-between items-center">
          <a
            className="link flex flex-row items-center text-xl gap-2"
            href={selectedCarparkInfo?.map}
            target="_blank"
          >
            <LocationIcon /> {selectedCarparkInfo?.location}
          </a>

          <GalleryButton />
        </div>
      )}

      <MonthlyOrderItemCard
        key={selectedMonthlyItem?.id}
        item={selectedMonthlyItem!}
        hideQuota
      />

      <BaseLabel text="選擇開始日期" />

      <Swiper
        className="flex w-full pb-4 [&_.swiper-scrollbar]:h-1 rounded-lg [&_.swiper-scrollbar-drag]:bg-mainYellow"
        slidesPerView={7}
        spaceBetween={10}
        freeMode={true}
        modules={[Scrollbar, FreeMode]}
        scrollbar={{
          hide: false,
          draggable: true,
        }}
      >
        {DAYS.map((day, index) => {
          const _isSelected = dayjs(startDate).isSame(dayjs(day), "d");
          return (
            <SwiperSlide key={index}>
              <div
                className={`group w-10 h-10 flex flex-col justify-center items-center font-bold text-white rounded-full cursor-pointer hover:bg-mainYellow hover:bg-opacity-50 ${
                  _isSelected ? "bg-mainYellow text-mainDark" : ""
                }`}
                onClick={() => setStartDate(dayjs(day).toDate())}
              >
                <div className="text-sm leading-4">
                  {dayjs(day).format("DD")}
                </div>
                <div className="text-xs leading-3">
                  {dayjs(day).format("MMM")}
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="flex flex-col gap-1">
        <BaseLabel text="起租日" />
        <div className="text-white font-bold">
          {dayjs(startDate).format("YYYY年MM月D日")}
        </div>
      </div>
      <BaseButton onClick={customGoNext} minWidth="w-full">
        下一步
      </BaseButton>
    </div>
  );
};

export default SelectRentDatePage;
