import dayjs from "dayjs";
import React, { HTMLAttributes, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import type { IGetMonthlyOrderResponse } from "../../../apis/order/type";
import {
  createExtraCarPlate,
  updateExtraCarPlate,
} from "../../../apis/subscription";
import { ReactComponent as InfoIcon } from "../../../assets/icons/infoIcon.svg";
import { getExtractParts } from "../../../helper/format";
import { customShowToast } from "../../../helper/toast";
import useGeneralStore from "../../../store/general";
import BaseButton from "../../atom/baseButton";
import BaseCarplateTextInput from "../../atom/baseCarplateTextInput ";
import BaseChip from "../../atom/baseChip";
import BaseDarkButton from "../../atom/baseDarkButton";
import BaseOctopusNumberTextInput from "../../atom/baseOctopusTextInput";
import BaseTitle from "../../atom/baseTitle";
import AddExtraCarplatePage, {
  type IExtraCarplateDetails,
} from "../stepContainer/addExtraCarplatePage";
import {
  ITEM_TYPE_MAPPING,
  ORDER_STATE_MAPPING,
} from "../subscriptionManageBlock";

type TCurrentMode = "preview" | "delete" | "increase";

interface IModifyExtraCarplateBlock {
  item: IGetMonthlyOrderResponse;
  refetchListCallback?: () => void;
}

type TModifyExtraCarplateBlockProps = IModifyExtraCarplateBlock &
  HTMLAttributes<HTMLDivElement>;

const ModifyExtraCarplateBlock: React.FC<TModifyExtraCarplateBlockProps> = (
  props
) => {
  const { item, refetchListCallback, ...otherProps } = props;
  const [currentMode, setCurrentMode] = useState<TCurrentMode>("preview");
  const [tobeRemovedExtraCarPlate, setTobeRemovedExtraCarPlate] = useState<
    number[]
  >([]);

  const itemInfo = ITEM_TYPE_MAPPING[item?.monthlyOrderItem?.item_type_id || 1];

  const setGlobalLoadingState = useGeneralStore((state) => state.setIsLoading);

  const leftButtonOnclick = () => {
    if (currentMode === "preview") {
      setCurrentMode("delete");
    } else if (currentMode === "delete") {
      setCurrentMode("preview");
    }
  };
  const rightButtonOnclick = () => {
    if (currentMode === "preview") {
      setCurrentMode("increase");
    } else if (currentMode === "delete") {
      removeSubscriptionItem();
    }
  };

  const toggleDeleteList = (id: number) => {
    if (tobeRemovedExtraCarPlate.includes(id)) {
      setTobeRemovedExtraCarPlate(
        tobeRemovedExtraCarPlate.filter((item) => item !== id)
      );
    } else {
      setTobeRemovedExtraCarPlate([...tobeRemovedExtraCarPlate, id]);
    }
  };

  // create new subscription item
  const createNewSubscriptionItem = async (
    targetAddRecords: IExtraCarplateDetails[]
  ) => {
    try {
      setGlobalLoadingState(true);
      //   call create subacription item api
      const requestArray = targetAddRecords.map((record) => {
        return {
          sub_car_plate: record.carplate,
          octopus_number: record.octopusNumber
            ? `${record.octopusNumber}(${record.octopusLastDigit})`
            : undefined,
        };
      });
      const createExtraCarPlateResponse = await createExtraCarPlate(
        item.token,
        requestArray
      );

      if (createExtraCarPlateResponse) {
        customShowToast(`新增成功`, "success");

        setGlobalLoadingState(false);
        if (refetchListCallback) {
          refetchListCallback();
        }
      }
    } catch (error) {
      setGlobalLoadingState(false);
    } finally {
      setGlobalLoadingState(false);
    }
  };

  // remove all subscription item
  const removeSubscriptionItem = async () => {
    try {
      setGlobalLoadingState(true);
      // call create subacription item api

      const deleteExtraCarPlateResponse = await updateExtraCarPlate(
        item.token,
        tobeRemovedExtraCarPlate
      );
      if (deleteExtraCarPlateResponse) {
        customShowToast(`移除成功`, "success");
        setGlobalLoadingState(false);
        if (refetchListCallback) {
          refetchListCallback();
        }
      }
    } catch (error) {
      setGlobalLoadingState(false);
    } finally {
      setGlobalLoadingState(false);
    }
  };

  console.log("current mode", currentMode);

  useEffect(() => {
    setTobeRemovedExtraCarPlate([]);
  }, [currentMode]);

  if (!item) return null;

  return (
    <div className="flex flex-col gap-4" {...otherProps}>
      <div className="flex flex-row items-center gap-1">
        <BaseTitle text="一位多泊計劃" />
        <BaseChip text={item.extraCarPlates.length > 0 ? "已升級" : "未升級"} />
      </div>

      {/* item detail section */}
      <div className="flex flex-col gap-1 bg-[#363C48] rounded-2xl p-4">
        {item.monthlyOrderItem.interval_count && (
          <BaseChip
            leftIcon={<InfoIcon />}
            text={
              item.monthlyOrderItem.interval_count > 1
                ? `${item.monthlyOrderItem.interval_count}個月季票計劃`
                : "月票"
            }
          />
        )}

        <span className="font-bold text-xl">{item.parking.display_name}</span>

        <div className="flex flex-row gap-2 items-center">
          {itemInfo.icon}
          <div className="text-xs font-bold">{itemInfo.title}</div>
        </div>

        <div className="flex flex-1 flex-row gap-1 items-baseline text-xs font-bold">
          <span>
            {item.monthlyOrderItem.interval_count > 1
              ? item.monthlyOrderItem.interval_count
              : "每"}
            個月
          </span>
          <span className="text-mainYellow text-xl">
            {item.monthlyOrderItem.price.toLocaleString("zh-HK", {
              style: "currency",
              currency: "HKD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
          </span>
          {item.monthlyOrderItem.interval_count > 1 && (
            <span>*需要一次性付款</span>
          )}
        </div>
      </div>

      {item.extraCarPlates.length > 0 && currentMode !== "increase" && (
        <div className="flex flex-col bg-selectedDark rounded-2xl p-4 gap-6">
          <div className="text-sm text-white font-bold">{`登記車牌數量x${item.extraCarPlates.length}`}</div>

          {/* main block */}
          <div>
            {item.extraCarPlates.map((extraCarPlate) => {
              const pending = extraCarPlate.extra_car_plate_status_id === 1;
              const active = extraCarPlate.extra_car_plate_status_id === 2;
              const pre_cancel = extraCarPlate.extra_car_plate_status_id === 3;
              const canceled = extraCarPlate.extra_car_plate_status_id === 4;

              const stateObject =
                ORDER_STATE_MAPPING[
                  extraCarPlate.extra_car_plate_status_id ?? 1
                ];
              // get octopus number and last digit
              const { numberPart, insideParentheses } = getExtractParts(
                extraCarPlate.octopus_number || ""
              );

              return (
                <div
                  className="flex flex-row gap-5 mb-4 border-white border-b py-4 justify-between"
                  key={extraCarPlate.id}
                >
                  {currentMode === "delete" && (
                    <input
                      disabled={pre_cancel || canceled}
                      type="checkbox"
                      className={twMerge(
                        "checkbox w-6 h-6 checkbox-warning rounded-md mt-2",
                        pre_cancel || canceled
                          ? "disabled:border-mainYellow disabled:border-2"
                          : ""
                      )}
                      checked={tobeRemovedExtraCarPlate.includes(
                        extraCarPlate.id
                      )}
                      onChange={() => toggleDeleteList(extraCarPlate.id)}
                    />
                  )}
                  <div className="flex flex-1 flex-col gap-5">
                    <BaseCarplateTextInput
                      value={extraCarPlate.sub_car_plate}
                      onChangeCarplate={() => {}}
                      disabled
                    />
                    <BaseOctopusNumberTextInput
                      octopusNumber={numberPart || ""}
                      octopusLastDigit={insideParentheses || ""}
                      onchangeBookingFormValues={() => {}}
                      disabled
                    />
                    <div className="flex flex-row gap-2 justify-end items-center text-xs">
                      <div className={twMerge(stateObject.color)}>
                        {stateObject.label}
                      </div>
                      {(pre_cancel || canceled) && (
                        <div className="text-white">
                          結束日期{" "}
                          {dayjs(extraCarPlate.end_date)
                            .utc()
                            .format("YYYY-MM-DD")}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          {/* button section */}
          <div className="flex flex-row justify-between items-center gap-4">
            <BaseDarkButton
              disabled={item.monthly_order_state_id !== 2}
              onClick={leftButtonOnclick}
              className="flex-1 "
            >
              {currentMode === "preview" ? "刪除" : "取消"}
            </BaseDarkButton>
            <BaseButton
              onClick={rightButtonOnclick}
              className="flex-1"
              disabled={
                item.monthly_order_state_id !== 2 ||
                (currentMode === "delete" &&
                  tobeRemovedExtraCarPlate.length === 0)
              }
            >
              {currentMode === "preview" ? "加入車牌" : "刪除車牌"}
            </BaseButton>
          </div>
        </div>
      )}

      {(currentMode === "increase" || item.extraCarPlates.length === 0) && (
        <AddExtraCarplatePage
          goBack={
            item.extraCarPlates.length === 0
              ? undefined
              : () => setCurrentMode("preview")
          }
          goNext={() => {}}
          customAction={(values) => createNewSubscriptionItem(values)}
          applyingItem={item}
        />
      )}
    </div>
  );
};

export default ModifyExtraCarplateBlock;
