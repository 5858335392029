// Import Swiper React components
import { EffectFade, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "./index.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import "swiper/css/scrollbar";
import { SwiperOptions } from "swiper/types/swiper-options";

interface IGallerySwiperProps {
  slideObject: string[];
}

const GallerySwiper = (props: IGallerySwiperProps & SwiperOptions) => {
  const { slideObject, ...otherProps } = props;

  return (
    <div className="relative w-full h-[70vh]">
      <Swiper
        className="flex w-full h-full justify-center items-center"
        autoHeight={true}
        slidesPerView={1}
        loop={true}
        centeredSlides={true}
        navigation={true}
        pagination={{
          clickable: true,
          el: ".custom-pagination",
        }}
        effect="fade"
        fadeEffect={{ crossFade: true }}
        modules={[Navigation, Pagination, EffectFade]}
        {...otherProps}
      >
        {slideObject.map((url, index) => {
          return (
            <SwiperSlide
              style={{
                display: "flex",
                justifyContent: "center",
                maxHeight: "70vh",
                overflow: "hidden",
              }}
              key={index}
            >
              <img style={{ objectFit: "contain" }} src={url} />
            </SwiperSlide>
          );
        })}
        <div className="custom-pagination"></div>
      </Swiper>
    </div>
  );
};

export default GallerySwiper;
