import React from "react";
import BaseModal, { type TBaseModalProps } from "../../atom/baseModal";
import GallerySwiper from "../gallerySwiper";

interface IGalleryPreviewModal {
  swiperData: string[];
}

type TGalleryPreviewModalProps = IGalleryPreviewModal & TBaseModalProps;

const GalleryPreviewModal: React.FC<TGalleryPreviewModalProps> = (props) => {
  const { swiperData, ...otherProps } = props;

  return (
    <BaseModal
      {...otherProps}
      modalBaseStyle="bg-selectedDark bg-opacity-50"
      customWidth="w-11/12 max-w-5xl"
      showCloseButton
    >
      <GallerySwiper slideObject={swiperData} />
    </BaseModal>
  );
};

export default GalleryPreviewModal;
