import React from "react";
import { useNavigate } from "react-router-dom";
import BaseButton from "../../atom/baseButton";

interface IGoHomeButton {}

type TGoHomeButtonProps = IGoHomeButton;

const GoHomeButton: React.FC<TGoHomeButtonProps> = (props) => {
  const navigate = useNavigate();
  const { ...otherProps } = props;

  return (
    <BaseButton
      onClick={() => navigate("/")}
      minWidth="w-full"
      className="bg-selectedDark bg-opacity-40 text-mainYellow border-mainYellow hover:border-mainYellow hover:bg-mainDark hover:bg-opacity-100"
    >
      回主頁
    </BaseButton>
  );
};

export default GoHomeButton;
