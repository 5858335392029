import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import React, { HTMLAttributes, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getParkingList } from "../../apis/parking";
import { getWaitingListByToken } from "../../apis/waitingList";
import type { IGetWaitingListStatusResponse } from "../../apis/waitingList/type";
import ClockIcon from "../../assets/icons/out-of-time.png";
import BaseLabel from "../../components/atom/baseLabel";
import BaseTitle from "../../components/atom/baseTitle";
import ItemDisplayCard from "../../components/atom/itemDisplayCard";
import GoHomeButton from "../../components/molecules/goHomeButton";
import AddExtraCarplatePage from "../../components/molecules/stepContainer/addExtraCarplatePage";
import ApplyForm from "../../components/molecules/stepContainer/applyForm";
import ConfirmForm from "../../components/molecules/stepContainer/confirmForm";
import ExtraCarplatePage from "../../components/molecules/stepContainer/extraCarplatePage";
import SelectRentDatePage from "../../components/molecules/stepContainer/selectRentDatePage";
import { queueStatusMap } from "../../constants";
import { getExtractParts } from "../../helper/format";
import { ROUTE_PATHS } from "../../routes/paths/route-paths";
import useCarparkStore from "../../store/carpark";
import useGeneralStore from "../../store/general";
import useInfoStateStore from "../../store/infoState";
import MovingCar from "./movingCar";

dayjs.extend(relativeTime);
dayjs.extend(duration);

interface IQueuePage {}

type TQueuePageProps = IQueuePage & HTMLAttributes<HTMLDivElement>;
type TQueueStatus = (typeof queueStatusMap)[keyof typeof queueStatusMap];

const QueuePage: React.FC<TQueuePageProps> = (props) => {
  const { ...otherProps } = props;
  const [searchParams] = useSearchParams();
  const _token = searchParams.get("wlt");
  const navigate = useNavigate();

  const [countDown, setCountDown] = useState("");
  const [orderDetails, setOrderDetails] =
    useState<IGetWaitingListStatusResponse>();
  const [queueStatus, setQueueStatus] = useState<TQueueStatus>();
  const [currentStep, setCurrentStep] = useState(3);

  const setGlobalLoadingState = useGeneralStore((state) => state.setIsLoading);

  const setCarparkInfoList = useCarparkStore(
    (state) => state.setCarParkInfoList
  );

  const { clearInfoState: resetInfoStore, ...infoStore } = useInfoStateStore(
    (state) => state
  );

  // get parking list api
  const fetchAllParkingList = async () => {
    try {
      setGlobalLoadingState(true);
      const carparkResponse = await getParkingList();
      if (carparkResponse) {
        setCarparkInfoList(carparkResponse);
        setGlobalLoadingState(false);
      }
    } catch (error) {
      setGlobalLoadingState(false);
    } finally {
      setGlobalLoadingState(false);
    }
  };

  const getQueueStatus = async (token: string) => {
    try {
      setGlobalLoadingState(true);
      const queueResult = await getWaitingListByToken(token);
      if (queueResult) {
        // control display related content by status
        setQueueStatus(
          queueStatusMap[
            queueResult.waiting_list_items.waiting_list_status_id ?? 1
          ]
        );

        setOrderDetails(queueResult);
        // preset data to store
        const { numberPart, insideParentheses } = getExtractParts(
          queueResult.waiting_list_items.octopus_number || ""
        );
        infoStore.setApplyFormData({
          mainCarPlate: queueResult.waiting_list_items.u_lpn,
          email: queueResult.waiting_list_items.u_email,
          name: queueResult.waiting_list_items.u_name,
          phone: queueResult.waiting_list_items.u_tel,
          octopusNumber: numberPart,
          octopusLastDigit: insideParentheses,
        });
        infoStore.setSelectCarpark(
          queueResult.waiting_list_items.parking.id.toString()
        );
        infoStore.setSelectItem(
          queueResult.waiting_list_items.monthlyOrderItem
        );
        setGlobalLoadingState(false);
      }
    } catch (error) {
      setGlobalLoadingState(false);
    } finally {
      setGlobalLoadingState(false);
    }
  };

  const goNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const goBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const goStep = (step: number) => {
    setCurrentStep(step);
  };
  // get queue status when token is ready
  useEffect(() => {
    fetchAllParkingList();
    if (_token) {
      resetInfoStore();
      getQueueStatus(_token);
    }
  }, [_token]);

  // trigger timer when status is active
  useEffect(() => {
    if (queueStatus === "completed") {
      console.log("completed");
      navigate(ROUTE_PATHS.LANDING_PAGE);
    }
    if (queueStatus !== "active") {
      return;
    }
    console.log("timer start");
    const timer = setInterval(() => {
      const now = dayjs();
      const diff = dayjs(orderDetails?.waiting_list_items?.expired_date).diff(
        now
      );

      if (diff <= 0) {
        clearInterval(timer);
        setCountDown("已超時");
      } else {
        const countdown = dayjs.duration(diff);
        const days = countdown.days();
        const hours = countdown.hours();
        const minutes = countdown.minutes();
        const seconds = countdown.seconds();
        setCountDown(`${days}天 ${hours}小時 ${minutes}分鐘 ${seconds} 秒`);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [queueStatus]);

  return (
    <div className="flex flex-1 flex-col gap-4" {...otherProps}>
      {/* pending */}
      {queueStatus === "pending" && (
        <div className="flex flex-col gap-4 mt-10">
          <BaseTitle className="text-center" text="輪候中…" />
          <div className="flex flex-row">
            <span className="text-xs text-white">
              您正在輪候以下停車場車位, 當有車位空缺時 ，
              <span className="text-mainYellow">我們會以SMS及電郵通知您。</span>
              <span>{` (如有需要,請保存此網頁連結來查看狀態。)`}</span>
            </span>
          </div>

          {orderDetails && (
            <ItemDisplayCard
              carpakName={orderDetails?.waiting_list_items.parking.display_name}
              interval_count={
                orderDetails.waiting_list_items.monthlyOrderItem.interval_count
              }
              map={orderDetails?.waiting_list_items.parking.map}
              itemName={
                orderDetails?.waiting_list_items.monthlyOrderItem.display_name!
              }
              itemTypeID={
                orderDetails?.waiting_list_items.monthlyOrderItem.item_type_id!
              }
            />
          )}

          <div className="flex flex-row justify-center item-center gap-2 text-center text-white">
            <div className="text-xl text-left font-bold self-center">
              現時輪候位置
            </div>

            <div className="text-5xl ml-10 font-extrabold text-mainYellow ">
              {orderDetails?.canadidate_number}
            </div>
          </div>
          <MovingCar />
          <div className="text-xs text-mainDisable font-medium text-center">
            {`(你亦可以透過刷新頁面來更新即時數據)`}
          </div>
        </div>
      )}

      {/* active */}
      {queueStatus === "active" && (
        <div className="flex flex-col gap-4 mt-10 ">
          <div className="flex flex-col gap-4">
            <BaseTitle
              className="text-center"
              text="請選擇開始日期及核對資料。"
            />
            <div className="text-2xl text-white font-extrabold text-center">
              {countDown}
            </div>
            <BaseLabel
              className="text-mainYellow"
              text="* 請於限時內點擊進行訂閱租用， 否則將自動失去租用優先權。"
            />
          </div>

          {/* selecte start date page */}
          {currentStep === 3 && <SelectRentDatePage goNext={goNext} />}

          {/* apply form  */}
          {currentStep === 4 && <ApplyForm goBack={goBack} goNext={goNext} />}

          {/* extra carplate page */}
          {currentStep === 5 && (
            <ExtraCarplatePage goBack={goBack} goStep={goStep} />
          )}

          {/* add extra carplate form */}
          {currentStep === 6 && (
            <AddExtraCarplatePage goBack={goBack} goNext={goNext} />
          )}

          {/* confirm form */}
          {currentStep === 7 && <ConfirmForm goStep={goStep} token={_token!} />}
        </div>
      )}

      {/* expired */}
      {queueStatus === "expired" && (
        <div className="flex flex-col gap-4 mt-10 justify-center items-center">
          <BaseTitle text="已超時" />
          <img src={ClockIcon} width={86} height={86} />
          <BaseLabel
            className="text-white font-bold"
            text={`抱歉，您的租用車位時限已過，請重新登記。`}
          />
          <GoHomeButton />
        </div>
      )}
    </div>
  );
};

export default QueuePage;
