import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; //CSS Style
import BaseLoading from "./components/atom/loading";
import StepContainer from "./components/molecules/stepContainer";
import useGeneralStore from "./store/general";

import Layout from "./components/layout";
import "./index.css";
import NotFoundPage from "./pages/notFound";
import PaymentResult from "./pages/paymentResult";

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import InquirePage from "./pages/inquire";
import QueuePage from "./pages/queue";
import RegisterPage from "./pages/register";
import SpecialEventPage from "./pages/specialEvent";
import { ROUTE_PATHS } from "./routes/paths/route-paths";

function App() {
  const globalLoadingState = useGeneralStore((state) => state.isLoading);

  const [isLoading, setIsLoading] = useState(false);

  const router = createBrowserRouter([
    {
      path: ROUTE_PATHS.LANDING_PAGE,
      element: <Layout />,
      children: [
        {
          index: true,
          element: <StepContainer />,
        },
        {
          path: ROUTE_PATHS.PAYMENT_RESULT_PAGE,
          element: <PaymentResult />,
        },
        {
          path: ROUTE_PATHS.QUEUE_PAGE,
          element: <QueuePage />,
        },
        {
          path: ROUTE_PATHS.INQUIRE_PAGE,
          element: <InquirePage />,
        },
        {
          path: ROUTE_PATHS.REGISTER_PAGE,
          element: <RegisterPage />,
        },
        {
          path: "special-event",
          element: <SpecialEventPage />,
        },
        {
          path: "*",
          element: <NotFoundPage />,
        },
      ],
    },
  ]);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

  useEffect(() => {
    setIsLoading(globalLoadingState);
  }, [globalLoadingState]);

  useEffect(() => {
    if (isLoading) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isLoading]);

  return (
    <Elements stripe={stripePromise}>
      <I18nextProvider i18n={i18n}>
        <RouterProvider router={router} />

        {isLoading && <BaseLoading />}
        <ToastContainer />
      </I18nextProvider>
    </Elements>
  );
}

export default App;
