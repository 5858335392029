import dayjs from "dayjs";
import React, { HTMLAttributes, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { getMonthlyOrdersByPhone } from "../../../apis/order";
import type { IGetMonthlyOrderResponse } from "../../../apis/order/type";
import { createCustomerPortal } from "../../../apis/subscription";
import { ReactComponent as HgvIcon } from "../../../assets/icons/HgvIcon.svg";
import { ReactComponent as LgvIcon } from "../../../assets/icons/LgvIcon.svg";
import { ReactComponent as BikeIcon } from "../../../assets/icons/bikeIcon.svg";
import { ReactComponent as CarIcon } from "../../../assets/icons/carIcon.svg";
import { ReactComponent as InfoIcon } from "../../../assets/icons/infoIcon.svg";
import RefreshIcon from "../../../assets/icons/refetch.png";
import useGeneralStore from "../../../store/general";
import BaseButton from "../../atom/baseButton";
import BaseChip from "../../atom/baseChip";
import BaseDarkButton from "../../atom/baseDarkButton";
import BaseModal from "../../atom/baseModal";
import BaseTitle from "../../atom/baseTitle";
import ModifyExtraCarplateBlock from "../modifyExtraCarplateBlock";

export const ITEM_TYPE_MAPPING: Record<
  number,
  { title: string; icon: React.ReactNode }
> = {
  1: {
    title: "私家車／客貨車",
    icon: <CarIcon />,
  },
  2: {
    title: "電單車",
    icon: <BikeIcon />,
  },
  3: {
    title: "LGV輕型貨車",
    icon: <LgvIcon />,
  },
  4: {
    title: "HGV重型貨車",
    icon: <HgvIcon />,
  },
  5: {
    title: "戶外貨車",
    icon: <HgvIcon />,
  },
};

export const ORDER_STATE_MAPPING: Record<
  number,
  { label: string; color: string }
> = {
  1: { label: "待啟用", color: "text-mainYellow" },
  2: { label: "訂閱中", color: "text-green-500" },
  7: { label: "待取消", color: "text-orange-500" },
  4: { label: "已取消", color: "text-alertRed" },
  8: { label: "已失效", color: "text-alertRed" },
};

interface ISubscriptionManageBlock {}

type TSubscriptionManageBlockProps = ISubscriptionManageBlock &
  HTMLAttributes<HTMLDivElement>;

const SubscriptionManageBlock: React.FC<TSubscriptionManageBlockProps> = (
  props
) => {
  const { ...otherProps } = props;
  const setGlobalLoadingState = useGeneralStore((state) => state.setIsLoading);
  const [currentPhone, setCurrentPhone] = useState<string>("");
  const [subscriptionData, setSubscriptionData] = useState<
    IGetMonthlyOrderResponse[]
  >([]);

  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showModifyExtraCarplateModal, setShowModifyExtraCarplateModal] =
    useState<IGetMonthlyOrderResponse | null>();
  const [managementUrl, setManagementUrl] = useState("");

  const onCloseAlertModal = () => {
    setShowAlertModal(false);
  };

  const onCloseModifyExtraCarplateModal = () => {
    setShowModifyExtraCarplateModal(null);
  };

  const fetchCreateStripeCustomerPortal = async (customerId: string) => {
    try {
      const portalResponse = await createCustomerPortal(customerId);
      if (portalResponse.url) {
        setManagementUrl(portalResponse.url);
        setShowAlertModal(true);
      }
    } catch (error) {
      console.log("error?");
    }
  };

  // fetch subscription history
  const fetchSubscriptionHistory = async () => {
    try {
      const loginState = JSON.parse(
        localStorage.getItem("wbloginState") || "{}"
      );
      setCurrentPhone(loginState.phone);
      setGlobalLoadingState(true);
      const inquireResult = await getMonthlyOrdersByPhone(loginState.phone); // TODO
      if (inquireResult) {
        setSubscriptionData(inquireResult);
        setGlobalLoadingState(false);
        setShowModifyExtraCarplateModal(null);
      }
    } catch (error) {
      setGlobalLoadingState(false);
    } finally {
      setGlobalLoadingState(false);
    }
  };

  useEffect(() => {
    fetchSubscriptionHistory();
  }, []);

  return (
    <div className="flex flex-col gap-4 px-4" {...otherProps}>
      <div className="flex flex-row justify-between items-center text-sm font-bold">
        <div className="text-white">
          歡迎來到 AI Parkingbees, {currentPhone}
        </div>
      </div>
      <div className="flex flex-row justify-between items-center">
        <BaseTitle text="訂閱記錄" />
        <button
          className="flex flex-row items-center"
          onClick={() => {
            fetchSubscriptionHistory();
          }}
        >
          <div className="text-white text-xs">刷新</div>
          <img src={RefreshIcon} width={14} height={14} />
        </button>
      </div>
      {subscriptionData.length === 0 && (
        <div className="flex flex-col rounded-2xl overflow-hidden">
          <div className="bg-mainDark p-4 text-white">暫無訂閱記錄</div>
        </div>
      )}

      <div className="">
        {subscriptionData.length > 0 &&
          subscriptionData
            .sort((a, b) => b.id - a.id)
            .map((item, index) => {
              // console.log(item.id);
              const isFreetrail = item.monthly_order_state_id === 1;
              const isActive = item.monthly_order_state_id === 2;
              const isPreCancel = item.monthly_order_state_id === 7;
              const isCanceled = item.monthly_order_state_id === 4;

              const itemInfo =
                ITEM_TYPE_MAPPING[item.monthlyOrderItem.item_type_id];
              const stateObject =
                ORDER_STATE_MAPPING[item.monthly_order_state_id ?? 8];
              if (stateObject) {
                return (
                  <div
                    className={twMerge(
                      "flex flex-col rounded-2xl overflow-hidden mb-4",
                      isCanceled ? "opacity-50" : ""
                    )}
                    key={index}
                  >
                    {/* header section */}
                    <div className="bg-[#0D1014] p-4">
                      <div className="flex flex-row justify-between items-center text-xs text-white mb-2">
                        <div>
                          落單時間{" "}
                          {dayjs(item.createdAt).utc().format("YYYY-MM-DD")}
                        </div>
                        <div className={stateObject?.color}>
                          {stateObject.label}
                        </div>
                      </div>

                      <span className="text-xs text-white">
                        訂單編號 {item.ref_no}
                      </span>
                    </div>

                    {/* body section */}
                    <div className="bg-mainDark p-4 text-white">
                      <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-1">
                          {item.monthlyOrderItem.interval_count && (
                            <BaseChip
                              leftIcon={<InfoIcon />}
                              text={
                                item.monthlyOrderItem.interval_count > 1
                                  ? `${item.monthlyOrderItem.interval_count}個月季票計劃`
                                  : "月票"
                              }
                            />
                          )}

                          <span className="font-bold text-xl">
                            {item.parking.display_name}
                          </span>

                          <div className="flex flex-row gap-2 items-center">
                            {itemInfo.icon}
                            <div className="text-xs font-bold">
                              {itemInfo.title}
                            </div>
                          </div>

                          <div className="flex flex-1 flex-row gap-1 items-baseline text-xs font-bold">
                            <span>
                              {item.monthlyOrderItem.interval_count > 1
                                ? item.monthlyOrderItem.interval_count
                                : "每"}
                              個月
                            </span>
                            <span className="text-mainYellow text-xl">
                              {item.monthlyOrderItem.price.toLocaleString(
                                "zh-HK",
                                {
                                  style: "currency",
                                  currency: "HKD",
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                }
                              )}
                            </span>
                            {item.monthlyOrderItem.interval_count > 1 && (
                              <span>*需要一次性付款</span>
                            )}
                          </div>
                        </div>

                        <div className="flex flex-row gap-1 text-xs justify-between">
                          <div className="flex flex-col gap-1 text-xs">
                            <div>車牌號碼: {item.u_lpn}</div>
                            <div>
                              {item.octopus_number
                                ? `八達通編號: ${item.octopus_number}`
                                : ""}
                            </div>
                          </div>
                          {/* date section */}
                          <div className="flex flex-col gap-1 text-xs">
                            <div>
                              開始日期:{" "}
                              {dayjs(item.start_date)
                                .utc()
                                .format("YYYY-MM-DD")}
                            </div>
                            {!isActive && !isFreetrail && (
                              <div>
                                完結日期:{" "}
                                {dayjs(item.end_date)
                                  .utc()
                                  .format("YYYY-MM-DD")}
                              </div>
                            )}
                          </div>
                        </div>

                        {/* button section */}
                        <div className="flex flex-row flex-1 justify-between items-center gap-4">
                          {!isCanceled && (
                            <BaseDarkButton
                              onClick={(e) => {
                                e.stopPropagation();
                                fetchCreateStripeCustomerPortal(
                                  item.customer_id
                                );
                              }}
                              className="flex-1 "
                            >
                              訂閱管理
                            </BaseDarkButton>
                          )}
                          {item.monthlyOrderItem
                            .stripe_extra_car_plate_price_id &&
                            !isCanceled && (
                              <BaseButton
                                onClick={() =>
                                  setShowModifyExtraCarplateModal(item)
                                }
                                className="flex-1 border-mainYellow"
                              >
                                一位多泊計劃
                              </BaseButton>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
      </div>

      {/* extra car plate popup */}
      {!!showModifyExtraCarplateModal && (
        <BaseModal
          titleStyle="text-white font-bold text-2xl "
          modalBaseStyle="h-full max-w-[400px] bg-selectedDark"
          showCloseButton={true}
          open={!!showModifyExtraCarplateModal}
          onClose={onCloseModifyExtraCarplateModal}
        >
          <ModifyExtraCarplateBlock
            item={showModifyExtraCarplateModal!}
            refetchListCallback={fetchSubscriptionHistory}
          />
        </BaseModal>
      )}

      {/* go stripe panel popup */}
      <BaseModal
        title="前往管理頁面"
        titleStyle="text-white font-bold text-2xl"
        customWidth="w-96"
        modalBaseStyle="bg-black"
        open={showAlertModal}
        onClose={onCloseAlertModal}
      >
        <div className="flex flex-col gap-4 text-white">
          <span className="py-4">
            您可以透過管理頁面查看收據，取消訂閱或更新信用卡資料。
          </span>
          <div className="flex flex-row justify-between items-center gap-4">
            <BaseDarkButton onClick={onCloseAlertModal} className="flex-1 ">
              取消
            </BaseDarkButton>
            <BaseButton
              onClick={() => {
                window.open(managementUrl, "_blank");
                onCloseAlertModal();
              }}
              className="flex-1"
            >
              確認
            </BaseButton>
          </div>
        </div>
      </BaseModal>
    </div>
  );
};

export default SubscriptionManageBlock;
