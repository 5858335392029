import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import type { IGetParkingItemsResponse } from "../apis/parking/type";
import type { IExtraCarplateDetails } from "../components/molecules/stepContainer/addExtraCarplatePage";
import type { IBookingFormValues } from "../components/molecules/stepContainer/applyForm/type";

type TInfoStateStore = {
  selectedCarparkID: string;
  setSelectCarpark: (carparkID: string) => void;
  selectedMonthlyItem: IGetParkingItemsResponse | null;
  setSelectItem: (item: IGetParkingItemsResponse) => void;
  clearSelectItem: () => void;
  selectedStartDate: Date | null;
  setSelectStartDate: (date: Date) => void;
  clearSelectStartDate: () => void;
  applyFormData: IBookingFormValues | null;
  setApplyFormData: (data: IBookingFormValues) => void;
  clearApplyFormData: () => void;
  extraCarPlateDetails: IExtraCarplateDetails[];
  setExtraCarPlateDetails: (details: IExtraCarplateDetails[] | []) => void;
  clearExtraCarPlateDetails: () => void;
  clearInfoState: () => void;
};

const initState = {
  selectedCarparkID: "",
  selectedMonthlyItem: null,
  selectedStartDate: null,
  applyFormData: null,
  extraCarPlateDetails: [],
};

const useInfoStateStore = create<TInfoStateStore>()(
  immer((set) => ({
    ...initState,
    setSelectCarpark: (carparkID: string) =>
      set((state) => ({ ...state, selectedCarparkID: carparkID })),
    setSelectItem: (item: IGetParkingItemsResponse) =>
      set((state) => ({ ...state, selectedMonthlyItem: item })),
    setSelectStartDate: (date: Date) =>
      set((state) => ({ ...state, selectedStartDate: date })),
    setApplyFormData: (data: IBookingFormValues) =>
      set((state) => ({ ...state, applyFormData: data })),
    setExtraCarPlateDetails: (details: IExtraCarplateDetails[]) =>
      set((state) => ({ ...state, extraCarPlateDetails: details })),
    clearSelectItem: () =>
      set((state) => ({ ...state, selectedMonthlyItem: null })),
    clearSelectStartDate: () =>
      set((state) => ({ ...state, selectedStartDate: null })),
    clearApplyFormData: () =>
      set((state) => ({ ...state, applyFormData: null })),
    clearExtraCarPlateDetails: () =>
      set((state) => ({ ...state, extraCarPlateDetails: [] })),
    clearInfoState: () => set(() => ({ ...initState })),
  }))
);

export default useInfoStateStore;
