import React, { useState, type HTMLProps } from "react";
import { twMerge } from "tailwind-merge";
import { ReactComponent as ClearBtn } from "../../../assets/icons/clearBtn.svg";
interface IBaseTextInput {
  clearAction?: () => void;
  rightElement?: React.ReactNode;
  errorMessage?: string;
}

export type TBaseTextInputProps = IBaseTextInput & HTMLProps<HTMLInputElement>;

const BaseTextInput: React.FC<TBaseTextInputProps> = (props) => {
  const {
    placeholder,
    type,
    value,
    required,
    clearAction,
    rightElement,
    errorMessage,
    ...otherProps
  } = props;
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const IS_FLOATING_LABEL = isFocused || value;

  return (
    <div className="flex flex-col gap-2 w-full text-base">
      <div
        className={twMerge(
          "relative flex flex-row flex-1 items-center justify-between w-full min-w-full max-w-xs bg-selectedDark rounded-full border border-white p-3",
          isFocused ? "border-mainYellow" : "",
          props.disabled ? "opacity-40" : ""
        )}
      >
        <input
          type={type || "text"}
          className={twMerge(
            "bg-selectedDark w-full rounded-full pl-4 text-white focus:outline-none"
          )}
          value={value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...otherProps}
        />

        {/* right element */}
        {rightElement && (
          <div className="flex flex-row justify-between items-center gap-2 bg-selectedDark">
            {clearAction && value && (
              <button
                className="btn btn-xs btn-circle bg-transparent border-none"
                onClick={clearAction}
              >
                <ClearBtn />
              </button>
            )}
            {rightElement}
          </div>
        )}

        {/*  floating label */}
        <label
          className={`absolute bg-selectedDark text-base text-secondaryDisable duration-300 px-3 z-10 start-4 pointer-events-none hover:cursor-text ${
            IS_FLOATING_LABEL ? "-top-[4px] -translate-y-2" : "top-[10px]"
          }`}
        >
          {`${placeholder}${required ? "*" : ""}`}
        </label>
      </div>
      {errorMessage && (
        <div className="flex w-full text-left text-xs text-alertRed pl-2">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default BaseTextInput;
