import React, { useState, type HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface Tab {
  label: string;
  content: React.ReactNode;
}

interface IBaseTabs {
  tabs: Tab[];
  onTabClick?: (key: number) => void;
}

type TBaseModalProps = IBaseTabs & HTMLAttributes<HTMLDivElement>;

const BaseTabs: React.FC<TBaseModalProps> = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();
  const { tabs, onTabClick, className, ...otherProps } = props;

  const handleTabClick = (index: number) => {
    setActiveTab(index);
    if (onTabClick) {
      onTabClick(index);
    }
  };

  return (
    <div>
      {/* tabs */}
      <div
        className={twMerge(
          "tabs bg-mainDark rounded-full p-[0.375rem] flex justify-evenly",
          className
        )}
        {...otherProps}
      >
        {tabs.map((tab, index) => (
          <a
            key={index}
            className={twMerge(
              `tab tab-bordered w-fit min-w-20 text-white text-xs rounded-full opacity-80 font-semibold ${activeTab === index ? "tab-active bg-selectedDark opacity-100" : ""}`
            )}
            onClick={() => handleTabClick(index)}
          >
            {`${t(tab.label)}`}
          </a>
        ))}
      </div>

      {/* tab content */}
      <div className="my-6">{tabs[activeTab].content}</div>
    </div>
  );
};

export default BaseTabs;
