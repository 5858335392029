import React, { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import UserIcon from "../../../assets/icons/userIcon.png";
import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
import { ROUTE_PATHS } from "../../../routes/paths/route-paths";
import BaseButton from "../../atom/baseButton";
interface IHeader {}

type THeaderProps = IHeader & HTMLAttributes<HTMLDivElement>;

const Header: React.FC<THeaderProps> = (props) => {
  const { ...otherProps } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToInquirePage = () => {
    navigate(ROUTE_PATHS.INQUIRE_PAGE);
  };

  return (
    <div
      className="flex flex-1 justify-between items-center p-4 bg-selectedDark border-b-2 border-menuActive"
      {...otherProps}
    >
      <a href="/">
        <Logo width={100} height={40} />
      </a>
      {/* <LanguageSwitcher /> */}
      <BaseButton onClick={navigateToInquirePage}>
        <img src={UserIcon} width={16} height={16} />
        {t("TXT_Header_Account")}
      </BaseButton>
    </div>
  );
};

export default Header;
