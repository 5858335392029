import React, { HTMLAttributes } from "react";

interface IBaseBreadCrumb {}

type TBaseBreadCrumbProps = IBaseBreadCrumb & HTMLAttributes<HTMLDivElement>;

const BaseBreadCrumb: React.FC<TBaseBreadCrumbProps> = (props) => {
  const { children, ...otherProps } = props;

  return (
    <div
      className="text-xs text-mainDisable mt-8 cursor-pointer"
      {...otherProps}
    >
      {children}
    </div>
  );
};

export default BaseBreadCrumb;
