import React, { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import packageJson from "../../../../package.json";
import Banner1 from "../../../assets/images/banner1.png";
import ADBanner from "../../../assets/images/banner3.png";
import BannerSwiper from "../swiper";

interface IFooter {}

type TFooterProps = IFooter & HTMLAttributes<HTMLDivElement>;

const APPVERSION = packageJson.version;

const Footer: React.FC<TFooterProps> = (props) => {
  const { t } = useTranslation();
  const { ...otherProps } = props;

  const SwiperContent = [
    { image: Banner1, link: "https://goswap.com.hk/rent-with-park" },
    {
      image: ADBanner,
      link: "https://wa.me/85294043381?text=%E2%80%8E%20%E4%BD%A0%E5%A5%BD%20Billy%20!%20%20%E6%83%B3%E6%9F%A5%E8%A9%A2%E8%8D%83%E7%81%A3%20IEC3%20%E8%BB%8A%E4%BD%8D%E5%87%BA%E5%94%AE%20~%20",
    },
  ];

  return (
    <div
      className="text-center flex flex-1 flex-col text-xs text-white gap-2 px-3 my-4"
      {...otherProps}
    >
      <BannerSwiper slideObject={SwiperContent} />
      <div className="flex flex-col flex-1 gap-1">
        <div>{t("TXT_CopyRight1")}</div>
        <div>{t("TXT_CopyRight2")}</div>
        <div className=" ">
          {t("TXT_Version")}: {APPVERSION}
        </div>
      </div>
    </div>
  );
};

export default Footer;
