import React, { HTMLAttributes } from "react";
import BaseLabel from "../../atom/baseLabel";
import ItemDisplayCard from "../../atom/itemDisplayCard";
import type { IExtraCarplateDetails } from "../stepContainer/addExtraCarplatePage";

interface IBookingDetailBlock {
  name: string;
  carPlate: string;
  phone: string;
  email: string;
  parkingName: string;
  parkingAddress: string;
  startDate: string;
  octopusNumber?: string;
  itemTypeID: number;
  itemName: string;
  interval_count: number;
  price: number;
  extra_car_plate_price: number;
  extraCarPlateDetails?: IExtraCarplateDetails[];
}

type TBookingDetailBlockProps = IBookingDetailBlock &
  HTMLAttributes<HTMLDivElement>;

const BookingDetailBlock: React.FC<TBookingDetailBlockProps> = (props) => {
  const {
    name,
    carPlate,
    phone,
    email,
    parkingName,
    parkingAddress,
    startDate,
    octopusNumber,
    itemTypeID,
    itemName,
    interval_count,
    price,
    extra_car_plate_price,
    extraCarPlateDetails,
    ...otherProps
  } = props;

  return (
    <div
      className="flex flex-col bg-[#363C48] rounded-2xl text-white text-xs font-medium px-4 py-5 gap-5"
      {...otherProps}
    >
      <div className="grid grid-cols-2 gap-4">
        <GridItem label="車主名稱" value={name} />
        <GridItem label="車牌" value={carPlate} />
        <GridItem label="電話" value={phone} />
        <GridItem label="電郵" value={email} />
        <GridItem label="停車場名稱" value={parkingName} />
        <GridItem label="停車場地址" value={parkingAddress} />
        <GridItem label="起租日期" value={startDate} />
        {octopusNumber && <GridItem label="八達通編號" value={octopusNumber} />}
      </div>

      <ItemDisplayCard itemName={itemName} itemTypeID={itemTypeID} />

      {extraCarPlateDetails && extraCarPlateDetails?.length > 0 && (
        <div className="">
          <BaseLabel text="一位多泊計劃" />
          <div className="flex flex-col gap-2 rounded-2xl bg-mainDark p-5">
            {extraCarPlateDetails.map((extraCarPlate, index) => {
              return (
                <div className="grid grid-cols-2 gap-4" key={index}>
                  <GridItem label="車牌號碼" value={extraCarPlate.carplate} />

                  <GridItem
                    label="八達通編號"
                    value={
                      extraCarPlate.octopusNumber
                        ? extraCarPlate.octopusLastDigit
                          ? `${extraCarPlate.octopusNumber}(${extraCarPlate.octopusLastDigit})`
                          : extraCarPlate.octopusNumber
                        : ""
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}

      <div className="flex flex-1 flex-col gap-1 items-center font-bold text-base ">
        <div className="flex flex-row flex-1">
          <span>{interval_count > 1 ? interval_count : "每"}個月訂閱</span>
          <span className="text-mainYellow">
            {price.toLocaleString("zh-HK", {
              style: "currency",
              currency: "HKD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
            {extraCarPlateDetails && extraCarPlateDetails?.length > 0 && (
              <span>
                +
                {(
                  extra_car_plate_price * extraCarPlateDetails?.length
                ).toLocaleString("zh-HK", {
                  style: "currency",
                  currency: "HKD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </span>
            )}
          </span>
        </div>
        {interval_count > 1 && <div>*需要一次性付款</div>}
      </div>
    </div>
  );
};

const GridItem = ({ label, value }: { label: string; value: string }) => {
  return (
    <div className="flex flex-col break-words">
      <BaseLabel text={label} />
      <div className="text-sm text-white font-bold break-words">{value}</div>
    </div>
  );
};

export default BookingDetailBlock;
